import { VlProgressBar, VlProgressBarStep } from '@govflanders/vl-ui-progress-bar/src/vue';
import { VlTitle } from '@govflanders/vl-ui-titles/src/vue';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlProgressBar,
    VlProgressBarStep,
    VlTitle,
  },
  props: {
    title: String,
    subtitle: String,
  },
  data() {
    return {
      panes: [],
      activeItemIndex: 0,
    };
  },
  provide() {
    return {
      panes: this.getPanes(),
    };
  },
  computed: {
    classes() {
      return [
        'vl-wizard',
      ];
    },
  },
  methods: {
    setActiveItem(index) {
      if (index < 0 || index >= get(this, 'panes.length') || (this.panes[this.activeItemIndex].modDisabled && index > this.activeItemIndex)) {
        return;
      }

      forEach(this.panes, (pane) => {
        pane.active = false;
      });

      this.panes[index].active = true;
      this.activeItemIndex = index;
      this.$emit('progress', index);

      if (index === this.panes.length - 1) {
        this.$emit('completed');
      }
    },
    getPanes() {
      return this.panes;
    },
  },
  mounted() {
    forEach(this.panes, (pane) => {
      pane.$on('next', () => { this.setActiveItem(this.activeItemIndex + 1); });
      pane.$on('prev', () => { this.setActiveItem(this.activeItemIndex - 1); });
    });

    if (this.panes.length) {
      this.panes[0].isFirst = true;
      this.panes[this.panes.length - 1].isLast = true;
      this.setActiveItem(0);
    }
  },
};
