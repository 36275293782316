import VlActionGroup from '@govflanders/vl-ui-action-group/src/vue';
import VlButton from '@govflanders/vl-ui-button/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlActionGroup,
    VlButton,
    VlLink,
  },
  props: {
    tagName: {
      type: String,
      default: 'section',
    },
    label: {
      type: String,
      required: true,
    },
    modDisabled: Boolean,
  },
  data() {
    return {
      active: false,
      isLast: false,
      isFirst: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-wizard__pane',
      ];
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.$nextTick(() => {
          this.$el.focus();
        });
      }
    },
  },
  inject: ['panes'],
  methods: {
    prevPane() {
      this.$emit('prev');
    },
    nextPane() {
      this.$emit('next');
    },
  },
  mounted() {
    if (this.panes) {
      this.panes.push(this);
    }
  },
};
