import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {},
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    classes() {
      return ['vl-tile-navigation'];
    },
  },
};
