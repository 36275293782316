import nlBE from './locale/nl-BE';

export default {
  i18n: {
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
      default: '',
    },
    imageAlt: String,
    data: Array,
    modQuote: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-person-info',
        { 'vl-person-info--quote': this.modQuote },
      ];
    },
  },
};
