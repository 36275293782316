import { VlAccordion } from '@govflanders/vl-ui-accordion/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlAccordion,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    footerLink: String,
    footerLinkText: String,
    schemaType: String,
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-contact-card',
      ];
    },
  },
};
