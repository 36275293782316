import VlIcon from '@govflanders/vl-ui-icon/src/vue/';

export default {
  props: {
    tagName: {
      type: String,
      default: 'a',
    },
    href: String,
    modService: {
      required: true,
      type: String,
      validator: value => ['facebook', 'twitter', 'linkedin', 'googleplus', 'mail'].includes(value),
    },
    text: {
      required: true,
      type: String,
    },
  },
  components: {
    VlIcon,
  },
  computed: {
    classes() {
      return [
        'vl-share-button',
        `vl-share-button--${this.modService}`,
      ];
    },
  },
};
