import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modError: Boolean,
    modSuccess: Boolean,
    modWarning: Boolean,
    modClickable: Boolean,
    modClosable: Boolean,
    modCheckable: Boolean,
    modDisabled: Boolean,
    closeLabel: String,
    id: [Number, String],
    value: null,
  },
  computed: {
    classes() {
      return [
        'vl-pill',
        {
          'vl-pill--error': this.modError,
          'vl-pill--success': this.modSuccess,
          'vl-pill--warning': this.modWarning,
          'vl-pill--closable': this.modClosable,
          'vl-pill--clickable': this.modClickable,
          'vl-pill--checkable': this.modCheckable,
          'vl-pill--disabled': this.modDisabled,
        },
      ];
    },
    tag() {
      if (this.modCheckable) {
        return 'label';
      }
      return this.tagName;
    },
  },
  methods: {
    close() {
      this.$emit('close', this);
    },
  },
};
