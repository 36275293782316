import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'nav',
    },
    title: String,
    titleTagName: {
      type: String,
      default: 'h2',
    },
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-side-navigation',
      ];
    },
  },
};
