import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    modLarge: Boolean,
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-progress', {
          'vl-progress--large': this.modLarge,
        },
      ];
    },
  },
};
