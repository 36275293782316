import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue/';
import nlBE from '../locale/nl-BE';

export default {
  locale: 'nl-BE',
  i18n: {
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlAnnotation,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    tagName: {
      type: String,
      default: 'li',
    },
    modLight: Boolean,
  },
  computed: {
    classes() {
      return 'vl-icon-list__item';
    },
  },
};
