import VlColumn from '../vl-column/vl-column.vue';

export default {
  extends: VlColumn,
  data() {
    return {
      formGridPrefix: 'form-',
    };
  },
};
