export default {
  props: {
    tagName: {
      type: String,
      default: 'hr',
    },
    modWave: Boolean,
    modDashed: Boolean,
  },

  computed: {
    classes() {
      return [
        'vl-u-hr',
        {
          'vl-u-hr--wave': this.modWave,
          'vl-u-hr--dashed': this.modDashed,
        },
      ];
    },
  },
};
