import Vue from 'vue';

export default {
  add(icon) {
    if (typeof Vue.prototype.$icons === 'undefined') {
      Vue.prototype.$icons = {};
    }
    Vue.prototype.$icons[icon.name] = icon;
  },
};
