<script>
import { VlAccordionListItem } from '@govflanders/vl-ui-accordion/src/vue';

export default {
  extends: VlAccordionListItem,
  props: {
    modNoTitle: Boolean,
  },
  computed: {
    classes() {
      return {
        'vl-accordion-list__item': true,
        'vl-sidebar-accordion-list-item': true,
        'vl-sidebar-accordion-list-item--no-title': this.modNoTitle,
      };
    },
  },
};
</script>
