export default
{
  filterResults: 'resultaten verfijnen',
  showAll: 'Toon alle',
  showLess: 'Toon minder',
  eraseFilters: 'Wis filters',
  close: 'Sluiten',
  showResults: 'Toon {amount} resultaten',
  showResult: 'Toon resultaat',
};
