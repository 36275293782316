export default {
  'generic.are': 'zijn',
  'generic.is': 'is',
  'drilldown.close_menu': 'Sluit menu',
  'drilldown.search': 'Zoek',
  'drilldown.back_to_level': 'Terug naar niveau $1',
  'drilldown.no_results': 'Geen resultaten gevonden',
  'slider.next_slide': 'volgende slide',
  'slider.prev_slide': 'vorige slide',
  'lightbox.next_slide': 'Volgende (Pijltje naar rechts)',
  'lightbox.prev_slide': 'Vorige (Pijltje naar links)',
  'lightbox.zoom_in_out': 'Zoom in/uit',
  'lightbox.close': 'Esc/sluiten',
  'codepreview.copy': 'Copy',
  'codepreview.copied': 'Copied',
  'codepreview.copy_to_clipboard': 'Copy to clipboard',
  'autocomplete.my_location': 'Mijn Locatie',
  'autocomplete.loading_location': 'Locatie ophalen...',
  'autocomplete.no_results': 'Geen resultaten',
  'autocomplete.no_results_found': 'Er zijn geen resultaten beschikbaar',
  'autocomplete.results_found': 'Er $2 $1 beschikbaar',
  'autocomplete.results': 'resultaten',
  'autocomplete.result': 'resultaat',
  'select.search_placeholder_value': 'Zoek item',
  'select.placeholder_value': 'Selecteer item',
  'select.no_results': 'Geen resultaten gevonden',
  'select.no_more_options': 'Geen resterende opties gevonden',
  'upload.add_files': 'Bijlage toevoegen',
  'upload.add_files_subtitle': 'Sleep de bijlage naar hier om toe te voegen',
  'upload.file_to_big': 'Het bestand mag maximaal $1 zijn.',
  'upload.file-type_not_allowed': 'Dit bestandstype is niet toegestaan.',
  'upload.to_many_files': 'Je kan maximaal $1 bestand(en) uploaden.',
  'upload.response_error': 'Er liep iets fout bij het uploaden.',
};
