import forEach from 'lodash/forEach';
import nlBE from './locale/nl-BE';

let VlLightbox;

if (typeof window !== 'undefined') {
  VlLightbox = require('@govflanders/vl-ui-lightbox/src/vue').default; /* eslint-disable-line */
}

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlLightbox,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modLightbox: Boolean,
    modTeaserA: Boolean,
    modTeaserB: Boolean,
    modTeaserC: Boolean,
    modSlider: Boolean,
    viewer: Boolean,
  },
  data() {
    return {
      lightboxItems: [],
    };
  },
  computed: {
    classes() {
      return [
        'vl-gallery',
        {
          'vl-gallery--teaser-a': this.modTeaserA,
          'vl-gallery--teaser-b': this.modTeaserB,
          'vl-gallery--teaser-c': this.modTeaserC,
          'vl-gallery--slider': this.modSlider,
        },
      ];
    },
  },
  mounted() {
    if (this.modLightbox && !this.modSlider) {
      const lightbox = this.$children.filter((item) => {
        if (item.classes) {
          return item.classes.includes('vl-lightbox');
        }
        return false;
      });
      let galleryItems = [];
      if (lightbox.length) {
        galleryItems = lightbox[0].$children.filter((item) => {
          if (item.classes) {
            return item.classes.includes('vl-gallery__item');
          }
          return false;
        });
      }

      forEach(galleryItems, (galleryItem) => {
        galleryItem.modLightbox = true;
        this.lightboxItems.push(galleryItem.$refs.lbi);
      });
    } else if (this.modLightbox && this.modSlider) {
      const lightbox = this.$children.filter((item) => {
        if (item.classes) {
          return item.classes.includes('vl-lightbox');
        }
        return false;
      });
      const slider = lightbox[0].$children.filter((item) => {
        if (item.classes) {
          return item.classes.includes('vl-slider');
        }
        return false;
      });
      const sliderItems = slider[0].$children;

      forEach(sliderItems, (sliderItem) => {
        const slide = sliderItem.$children[0];
        const galleryItem = slide.$children[0];

        galleryItem.modLightbox = true;

        const lightBoxItem = galleryItem.$refs.lbi;

        this.lightboxItems.push(lightBoxItem);
      });
    } else if (this.modSlider && this.viewer) {
      const slider = this.$children[0];
      const sliderItems = slider.$children;

      forEach(sliderItems, (sliderItem, index) => {
        const slide = sliderItem.$children[0];
        const galleryItem = slide.$children[0];

        galleryItem.$on('click-gallery-item', () => {
          this.$refs.galleryViewerImage.src = galleryItem.$props.link;
        });

        // set the first image as active
        if (index === 0) {
          this.$refs.galleryViewerImage.src = galleryItem.$props.link;
        }
      });
    }
  },
};
