import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    value: String,
    text: String,
    modBadge: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-infotext',
        { 'vl-infotext--badge': this.modBadge },
      ];
    },
  },
};
