import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    modError: Boolean,
    modSuccess: Boolean,
    modDisabled: Boolean,
    modBlock: Boolean,
    value: null,
  },
  computed: {
    classes() {
      return [
        'vl-textarea', {
          'vl-textarea--error': this.modError,
          'vl-textarea--success': this.modSuccess,
          'vl-textarea--disabled': this.modDisabled,
          'vl-textarea--block': this.modBlock,
        },
      ];
    },
    inputListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: (event) => {
            vm.$emit('input', event.target.value);
          },
        },
      );
    },
  },
};
