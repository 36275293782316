export default {
  props: {
    /**
     * Disable button
     */
    modDisabled: Boolean,
    /**
     * Error variant of button
     */
    modError: Boolean,
    /**
     * Show icon in button
     */
    modIcon: Boolean,
    /**
     * Show icon in button after text
     */
    modIconAfter: Boolean,
    /**
     * Show icon in button before text
     */
    modIconBefore: Boolean,
    /**
     * Narrow variant of button
     */
    modNarrow: Boolean,
    /**
     * Block variant of button
     */
    modBlock: Boolean,
    /**
     * Large variant of button
     */
    modLarge: Boolean,
    /**
     * Button with a loading animation
     */
    modLoading: Boolean,
    /**
     * Wide variant of button
     */
    modWide: Boolean,
    /**
     * Secondary variant of button
     */
    modSecondary: Boolean,
    /**
     * Tertiary variant of button
     */
    modTertiary: Boolean,
    /**
     * Naked variant of the button
     */
    modNaked: Boolean,
    /**
     * Naked action variant of the button
     */
    modNakedAction: Boolean,
  },
  computed: {
    buttonClasses() {
      const clazz = 'vl-button';
      return [
        clazz,
        {
          [`${clazz}--disabled`]: this.modDisabled || this.modLoading,
          [`${clazz}--error`]: this.modError,
          [`${clazz}--icon`]: this.modIcon,
          [`${clazz}--icon-after`]: this.modIconAfter,
          [`${clazz}--icon-before`]: this.modIconBefore,
          [`${clazz}--narrow`]: this.modNarrow,
          [`${clazz}--block`]: this.modBlock,
          [`${clazz}--large`]: this.modLarge,
          [`${clazz}--loading`]: this.modLoading,
          [`${clazz}--wide`]: this.modWide,
          [`${clazz}--secondary`]: this.modSecondary,
          [`${clazz}--tertiary`]: this.modTertiary,
          [`${clazz}--naked`]: this.modNaked,
          [`${clazz}--naked-action`]: this.modNakedAction,
          [`${clazz}--ellipse`]: this.modEllipse,
        },
      ];
    },
    buttonIconClasses() {
      const clazz = 'vl-button__icon';
      return [
        clazz,
        {
          [`${clazz}--after`]: this.modIconAfter || this.modExternal,
          [`${clazz}--before`]: this.modIconBefore,
        },
      ];
    },
    buttonLabelClasses() {
      const clazz = 'vl-button__label';
      return {
        [`${clazz}`]: !this.modLink,
      };
    },
  },
};
