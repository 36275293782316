export default {
  data() {
    return {
      layer: undefined,
      source: undefined,
    };
  },
  computed: {
    map() {
      return this.$parent.olMap;
    },
  },
  watch: {
    layer: {
      immediate: true,
      handler(layer, existingLayer) {
        if (layer && !existingLayer) {
          this.map.addLayer(layer);
        }
      },
    },
  },
};
