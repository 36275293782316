import VlInputField from '@govflanders/vl-ui-input-field/src/vue';
import nlBE from './locale/nl-BE';

let Choices;

if (typeof window !== 'undefined') {
  Choices = require('choices.js'); /* eslint-disable-line global-require */
}

export default {
  inheritProps: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlInputField,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    id: [Number, String],
    placeholderText: String,
    duplicateItems: {
      type: Boolean,
      default: true,
    },
    value: null,
    emailValidation: Boolean,
    modDisabled: Boolean,
  },

  data() {
    return {
      choice: null,
    };
  },

  computed: {
    classes() {
      return [
        'vl-pill-input',
      ];
    },
    config() {
      return {
        duplicateItemsAllowed: this.duplicateItems,
        addItemFilterFn: this._addItemFilterFn,
        removeItemButton: true,
        shouldSortItems: false,
        editItems: true,
        classNames: {
          containerOuter: 'vl-pill-input',
          containerInner: 'vl-pill-input__inner',
          input: 'vl-pill__input',
          list: 'vl-pill-input__list',
          listItems: 'vl-pill-input__list--multiple',
          item: 'vl-pill',
          itemClickable: 'vl-pill--clickable',
          button: 'vl-pill__close',
          notice: 'vl-form__annotation',
        },
        noResultsText: 'Geen resultaten gevonden',
        uniqueItemText: value => `Item "${value}" werd al toegevoegd`,
        addItemText: (value) => {
          if (value) {
            return `Druk Enter om <b>"${value}"</b> toe te voegen`;
          }
          const annotationEl = this.$el.querySelector('.vl-form__annotation');
          if (annotationEl) {
            annotationEl.remove();
          }
          return false;
        },
        maxItemText: maxItemCount => `Er kunnen maar ${maxItemCount} items worden toegevoegd.`,
        callbackOnCreateTemplates: this._createTemplates,
      };
    },
  },
  mounted() {
    const inputEl = this.$refs['pill-input-field'].$el;
    if (inputEl) {
      inputEl.value = this.value || null;
      this.choice = new Choices(inputEl, this.config);
      if (this.modDisabled) {
        this.choice.disable();
      }
      this.choice.passedElement.element.addEventListener('change', () => {
        const values = this.choice.getValue(true);
        for (let i = 0; i < values.length; i++) {
          values[i] = this._decodeValue(values[i]);
        }
        this.$emit('input', values);
      }, false);
    }
  },
  beforeDestroy() {
    this.choice.passedElement.element.removeEventListener('change', () => {});
    this.choice.destroy();
  },
  watch: {
    value() {
      this.choice.clearInput();
      this.choice.clearStore();
      this.choice.setValue(this.value);
    },
    modDisabled(disabled) {
      if (disabled) {
        this.choice.disable();
      } else {
        this.choice.enable();
      }
    },
  },
  methods: {
    _decodeValue(label) {
      const txtEl = document.createElement('span');
      const labelData = label.replace(/&rt;/g, '&gt;');
      txtEl.innerHTML = labelData;
      const labelTxt = txtEl.innerText.replace(/["]/g, '\'');

      return labelTxt;
    },
    _addItemFilterFn(value) {
      let isValid = true;
      if (this.emailValidation) {
        // eslint-disable-next-line max-len
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValid = emailRegex.test(value);
      }
      return isValid;
    },
    _createTemplates(template) {
      return {
        item: (classNames, data) => template(
          `
            <div class="${classNames.item}"
              data-item data-id="${data.id}"
              data-value="${data.value}"
              ${data.active ? 'aria-selected="true"' : ''}
              ${this.modDisabled ? 'aria-disabled="true"' : ''}
              data-deletable>
                <span>${this._decodeValue(data.label)}</span>
                <a${this.modDisabled ? '' : ' href="#"'} class="${classNames.button}" data-button>
                  <span class="${classNames.button}__icon" aria-hidden="true"></span>
                  <span class="vl-u-visually-hidden">Verwijder</span>
                </a>
            </div>
          `,
        ),
        choice: (classNames, data) => template(
          `
            <div class='${classNames.item}
              ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}'
              data-select-text="${this.config.itemSelectText}"
              data-choice
              ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
              data-id="${data.id}"
              data-value="${data.value}"
              ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
              ${this._decodeValue(data.label)}
            </div>
          `,
        ),
        notice: (classNames, label) => template(`
            <div class="${classNames.notice}">
              ${this._decodeValue(label)}
            </div>
            `),
      };
    },
  },
};
