import { VlButton } from '@govflanders/vl-ui-button/src/vue';
import { VlLink } from '@govflanders/vl-ui-link/src/vue';

import FocusLock from 'vue-focus-lock';
import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlButton,
    VlLink,
    FocusLock,
  },
  props: {
    /**
     * Use alternative styling for component -> White background instead of gray
    */
    modAlt: {
      type: Boolean,
      default: false,
    },
    /**
     * Form action
    */
    action: {
      type: String,
      default: null,
    },
    /**
     * Text used on the submit-button
    */
    submitText: {
      type: String,
      default() {
        return this.$t('filterResults');
      },
    },
    /**
     * Text used on the clear filters button
    */
    eraseFiltersText: {
      type: String,
      default() {
        return this.$t('eraseFilters');
      },
    },
    /**
     * Use alternative styling for mobile component, uses a modal on mobile
    */
    modMobileModal: {
      type: Boolean,
      default: false,
    },
    /**
     * Used in conjunction with modMobileModal, pass through amount of results which will be shown as text in the button
    */
    amountResults: {
      type: Number,
      default: null,
    },
    /**
     * Text to show on button when there are no results, works in conjunction with modMobileModal
    */
    modalCloseText: {
      type: String,
      default() {
        return this.$t('close');
      },
    },
    /**
     * Text to show on button when there is one result, works in conjunction with modMobileModal
    */
    showResultText: {
      type: String,
      default() {
        return this.$t('showResult');
      },
    },
    /**
     * Text to show on button when there are multiple results, the amount should be speficied by the implementor
    */
    showResultsText: {
      type: String,
      default: null,
    },
    /**
     * Disable Focus lock, only works if mod-mobile-modal is active. Else focus lock is not active.
    */
    modFocusLockDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Optional opened-prop, mainly to indicate the filter is opened in the modal-version
    */
    opened: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable the option to add the 'clearFilters' button
    */
    modShowClearFilters: {
      type: Boolean,
      default: false,
    },
    /**
     * Set loading state on search filter button
    */
    modLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'vl-search-filter',
        { 'vl-search-filter--alt': this.modAlt },
        { 'vl-search-filter--mobile-modal': this.modMobileModal },
      ];
    },
    focusLockDisabled() {
      return this.modMobileModal ? this.modFocusLockDisabled && !this.opened : true;
    },
    submitListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          input: (value) => {
            vm.$emit('submit', value);
          },
        },
      );
    },
    showResultsTextComputed() {
      if (this.amountResults) {
        return this.showResultsText
          ? this.showResultsText : this.$t('showResults', { amount: this.amountResults });
      }

      return null;
    },
    focusLockTag() {
      return this.modMobileModal && !this.modFocusLockDisabled ? 'focus-lock' : 'div';
    },
  },
  methods: {
    showResult() {
      /**
       * Emits a 'show-result' event
      */
      this.$emit('showResult');
    },
    /**
    * Emits an event 'clear-filters' that can be used to implement logic to clear all filters
    */
    clearFilters() {
      this.$emit('clearFilters', this);
    },
  },
};
