import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    label: String,
    modName: Boolean,
    prop: String,
    link: String,
  },
  // Computed properties
  computed: {
    classes() {
      return [
        {
          'vl-contact-card__data__name': this.modName,
        },
      ];
    },
  },
};
