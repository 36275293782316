import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    modBlock: Boolean,
    modLight: Boolean,
    modStandalone: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-form__label',
        {
          'vl-form__label--block': this.modBlock,
          'vl-form__label--light': this.modLight,
          'vl-form__label--standalone': this.modStandalone,
        },
      ];
    },
  },
};
