import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'h1',
    },
    title: {
      type: String,
      required: true,
    },
    href: String,
    context: {
      href: String,
      title: String,
    },
  },
  computed: {
    classes() {
      return [
        'vl-content-header__title',
        this.href ? 'vl-content-header__has-link' : false,
      ];
    },
    contextClasses() {
      return [
        'vl-content-header__context',
        this.context.href ? 'vl-content-header__context--has-link' : false,
      ];
    },
    childClasses() {
      return [
        this.href ? 'vl-content-header__title__link' : 'vl-content-header__title__content',
      ];
    },
    linkTag() {
      return this.href ? 'a' : 'span';
    },
    contextTag() {
      return this.context.href ? 'a' : 'span';
    },
  },
};
