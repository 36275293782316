import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    classes() {
      return [
        'vl-properties__list',
      ];
    },
  },
};
