import VlAlert from '@govflanders/vl-ui-alert/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlAlert,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modTopRight: Boolean,
    modTopLeft: Boolean,
    modBottomRight: Boolean,
    modBottomLeft: Boolean,
    fadeOut: Boolean,
  },
  data() {
    return {
      alerts: [],
    };
  },
  computed: {
    classes() {
      return [
        'vl-toaster',
        {
          'vl-toaster--top-right': this.modTopRight,
          'vl-toaster--top-left': this.modTopLeft,
          'vl-toaster--bottom-right': this.modBottomRight,
          'vl-toaster--bottom-left': this.modBottomLeft,
        },
      ];
    },
  },
  mounted() {
    this.alerts = this.$children;
    for (let i = 0; i < this.alerts.length; i++) {
      const alert = this.alerts[i];
      alert.$on('close', () => {
        this.closeAlert(alert, this.fadeOut);
      });
    }
    if (this.alerts.length > 3) {
      this.closeAlert(this.alerts.shift(), false);
    }
  },
  methods: {
    closeAlert(alert, fade = false) {
      if (fade) {
        alert.modFadeOut = true;
        window.setTimeout(() => {
          alert.visible = false;
        }, 300);
      } else {
        alert.visible = false;
      }
    },
  },
};
