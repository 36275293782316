import forEach from 'lodash/forEach';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    tagNameList: {
      type: String,
      default: 'ul',
    },
    modAlign: String,
    modAlignL: String,
    modAlignM: String,
    modAlignS: String,
    modAlignXs: String,
  },
  computed: {
    classes() {
      const classArray = ['vl-pager'];

      const breakpoints = [
        { key: '', align: this.modAlign },
        { key: 'l', align: this.modAlignL },
        { key: 'm', align: this.modAlignM },
        { key: 's', align: this.modAlignS },
        { key: 'xs', align: this.modAlignXs },
      ];

      forEach(breakpoints, (breakpoint) => {
        if (breakpoint.align && ['left', 'center', 'right'].includes(breakpoint.align)) {
          let className = `vl-pager--align-${breakpoint.align}`;

          if (breakpoint.key) {
            className += `--${breakpoint.key}`;
          }

          classArray.push(className);
        }
      });

      return classArray;
    },
  },
};
