import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modBordered: Boolean,
  },
  computed: {
    classes() {
      return [
        // Default
        'vl-description-data',
        {
          'vl-description-data--bordered': this.modBordered,
        },
      ];
    },
  },
};
