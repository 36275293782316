import '@govflanders/vl-ui-util/src/js/util';

// importing feature-detection
import './feature-detection/js-detection';
import Promise from 'promise-polyfill';
import 'intersection-observer';
// importing polyfill
import 'classlist-polyfill';
import './polyfill/array-from';
import './polyfill/closest';
import './polyfill/foreach';
import './polyfill/map';
import './polyfill/object-assign';
import './polyfill/template';
import './polyfill/trunk';
import './polyfill/customevent';
import './polyfill/includes';
import './polyfill/find';

// setup translation helper
import Translation from './i18n/i18n';

window.vl = window.vl || {};
vl.ns = vl.ns || 'vl-';

// To add to window
if (!window.Promise) {
  window.Promise = Promise;
}

vl.i18n = new Translation();
