export default
{
  // translation: 'vertaling',
  placeholder: 'Kies een of meerdere items',
  selectLabel: 'Selecteer',
  selectedLabel: 'Geselecteerd',
  selectGroupLabel: 'Selecteer groep',
  deselectLabel: 'Verwijder',
  deselectGroupLabel: 'Verwijder groep',
};
