import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from './locale/nl-BE';

export default {
  components: {
    VlIcon,
  },
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'ul',
    },
    modLine: {
      type: Boolean,
      default: false,
    },
    modTimeline: {
      type: Boolean,
      default: false,
    },
    modTimelineSimple: {
      type: Boolean,
      default: false,
    },
    modToggleAll: {
      type: Boolean,
      default: false,
    },
    modSub: {
      type: Boolean,
      default: false,
    },
    modLastStepNoLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'vl-steps', {
          'vl-steps--has-line': this.modLine,
          'vl-steps--timeline': this.modTimeline,
          'vl-steps--timeline-simple': this.modTimelineSimple,
          'vl-steps--last-step-no-line': this.modLastStepNoLine,
          'vl-steps--sub': this.modSub,
          'vl-step__substeps': this.modSub,
        },
      ];
    },
  },
};
