import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import VlLink from '@govflanders/vl-ui-link/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  inheritAttrs: false,
  components: {
    VlIcon,
    VlLink,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    mimeType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    textClampTitle: {
      type: Number,
      default: 2,
    },
    metadata: String,
    external: Boolean,
  },
  computed: {
    target() {
      return this.external ? '_blank' : null;
    },
  },
};
