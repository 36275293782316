import VlIcon from './vl-icon.vue';
import VlIconWrapper from './vl-icon-wrapper.vue';
import VlIconLib from './vl-icon-lib.js';

export default VlIcon;

export {
  VlIcon,
  VlIconWrapper,
  VlIconLib,
};
