import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Display as bullet, smallest variant
    */
    modBullet: {
      type: Boolean,
      default: false,
    },
    /**
     * XXS size
    */
    modXxsmall: {
      type: Boolean,
      default: false,
    },
    /**
     * XS size
    */
    modXsmall: {
      type: Boolean,
      default: false,
    },
    /**
     * S size
    */
    modSmall: {
      type: Boolean,
      default: false,
    },
    /**
     * M size
    */
    modMedium: {
      type: Boolean,
      default: false,
    },
    /**
     * L size
    */
    modLarge: {
      type: Boolean,
      default: false,
    },
    /**
     * XL size
    */
    modXlarge: {
      type: Boolean,
      default: false,
    },
    /**
     * XXL size
    */
    modXxlarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Alternative background color
    */
    modAlt: {
      type: Boolean,
      default: false,
    },
    /**
     * White background color
    */
    modWhite: {
      type: Boolean,
      default: false,
    },
    /**
     * Accent background color
    */
    modAccent: {
      type: Boolean,
      default: false,
    },
    /**
     * Action background color
    */
    modAction: {
      type: Boolean,
      default: false,
    },
    /**
     * Success state
    */
    modSuccess: {
      type: Boolean,
      default: false,
    },
    /**
     * Warning state
    */
    modWarning: {
      type: Boolean,
      default: false,
    },
    /**
     * Error state
    */
    modError: {
      type: Boolean,
      default: false,
    },
    /**
     * Adds a border
    */
    modBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * Display as block element
    */
    modBlock: {
      type: Boolean,
      default: false,
    },
    /**
     * Display as inline element
    */
    modInline: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon
    */
    icon: String,
    /**
     * Initials
    */
    initials: String,
    /**
     * a11y label for screenreaders
    */
    hiddenLabel: String,
    /**
     * Is a notification badge
     */
    modNotification: {
      type: Boolean,
      default: false,
    },
    /**
     * Display notification at the top
    */
    modNotificationTop: {
      type: Boolean,
      default: false,
    },
    /**
     * Display notification at the bottom
    */
    modNotificationBottom: {
      type: Boolean,
      default: false,
    },
    /**
     * Display notification on the left
    */
    modNotificationLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Display notification on the right
    */
    modNotificationRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconClasses: ['vl-badge__icon'],
      isImageIcon: false,
    };
  },
  updated() {
    this.isImageIcon = !!this.$slots['image-icon'];
  },
  mounted() {
    this.isImageIcon = !!this.$slots['image-icon'];
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-badge', {
          'vl-badge--inline': this.modInline,
          'vl-badge--bullet': this.modBullet,
          'vl-badge--xxsmall': this.modXxsmall,
          'vl-badge--xsmall': this.modXsmall,
          'vl-badge--small': this.modSmall,
          'vl-badge--medium': this.modMedium,
          'vl-badge--large': this.modLarge,
          'vl-badge--xlarge': this.modXlarge,
          'vl-badge--xxlarge': this.modXxlarge,
          'vl-badge--alt': this.modAlt,
          'vl-badge--white': this.modWhite,
          'vl-badge--accent': this.modAccent,
          'vl-badge--action': this.modAction,
          'vl-badge--success': this.modSuccess,
          'vl-badge--warning': this.modWarning,
          'vl-badge--error': this.modError,
          'vl-badge--border': this.modBorder,
          'vl-badge--block': this.modBlock,
          'vl-badge--icon': this.icon || this.isImageIcon,
          'vl-badge--initials': this.initials,
        },
      ];
    },
    notificationClasses() {
      return [
        'vl-badge__notification', {
          'vl-badge__notification--top': this.modNotificationTop,
          'vl-badge__notification--right': this.modNotificationRight,
          'vl-badge__notification--bottom': this.modNotificationBottom,
          'vl-badge__notification--left': this.modNotificationLeft,
        },
      ];
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
  },
};
