let lastClicked = null;

export default {
  bind(el, binding, vnode) {
    vnode.context.$root.$on('closed', () => {
      if (lastClicked) {
        window.setTimeout(() => {
          if (lastClicked) {
            lastClicked.focus();
            lastClicked = null;
          }
        }, 10);
      }
    });

    if (binding.value) {
      el.addEventListener('click', (event) => {
        if (!el.closest('dialog')) {
          lastClicked = el;
        }

        vnode.context.$root.$emit('modal-toggle', binding.value);

        event.preventDefault();
      });
    } else {
      // eslint-disable-next-line
      console.warn('[Value required]: no value on the directive v-vl-modal-toggle');
    }
  },
};
