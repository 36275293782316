import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'a',
    },
    title: {
      type: String,
      required: true,
    },
    datePrimary: String,
    dateSecondary: String,
  },
  computed: {
    classes() {
      return [
        'vl-info-tile',
        'vl-info-tile--date', {
          'vl-info-tile--clickable': this.$attrs.href,
        },
      ];
    },
    defaultSlotFilled() {
      return !!this.$slots.default;
    },
  },
};
