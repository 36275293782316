import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
