import nl from './translations/nl';

class Translation {
  constructor() {
    this.i18n = nl;
  }

  t(key, ...args) {
    let trans = this.i18n[key];

    if(trans) {
      let i = 0;

      for (let arg of args) {
        i++;
        trans = trans.replace('$' + i, arg.toString());
      }
    } else {
      return key;
    }
    return trans;
  }
}

export default Translation;
