const VlUBorder = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-border';

    if (binding.value || typeof binding.value === 'undefined') {
      el.classList.add(`${baseClass}`);
    }
  },
};

export default VlUBorder;

export { VlUBorder };
