import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },

  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    modChapter: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-duration-step',
        {
          'vl-duration-step--chapter': this.modChapter,
        },
      ];
    },
  },
};
