import VlOlMapLayer from '../vl-ol-map-layer/vl-ol-map-layer.vue';

let VectorLayer;
let VectorSource;

if (typeof window !== 'undefined') {
  VectorLayer = require('ol/layer/Vector').default; /* eslint-disable-line */
  VectorSource = require('ol/source/Vector').default; /* eslint-disable-line */
}

export default {
  mixins: [VlOlMapLayer],
  data() {
    return {
      style: undefined,
    };
  },
  watch: {
    source(source) {
      this.layer.setSource(source);
      if (this.style) {
        this.layer.setStyle(this.style);
      }
    },
    style(style) {
      if (this.layer) {
        this.layer.setStyle(style);
      }
    },
  },
  mounted() {
    this.layer = new VectorLayer({
      source: new VectorSource(),
    });
  },
};
