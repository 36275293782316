export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Add spacing between columns.
     */
    modStacked: Boolean,
    /**
     * Add more spacing between columns.
     */
    modStackedLarge: Boolean,
    /**
     * Add a little spacing between columns.
     */
    modStackedSmall: Boolean,
    /**
     * Items are packed toward the start of the writing-mode direction.
     */
    modStart: Boolean,
    /**
     * Items are packed toward the center of the writing-mode direction.
     */
    modCenter: Boolean,
    /**
     * Items are packed toward the end of the writing-mode direction.
     */
    modEnd: Boolean,
    /**
     *  Items are evenly distributed in the line; first item is on the start line, last item on the end line
     */
    modSpaceBetween: Boolean,
    /**
     * Items are evenly distributed in the line with equal space around them.
     * Note that visually the spaces aren’t equal, since all the items have equal space on both sides.
     * The first item will have one unit of space against the container edge,
     * but two units of space between the next item because that next item has its own spacing that applies.
     */
    modSpaceAround: Boolean,
    /**
     * Items have no space in between.
     */
    modNoGutter: Boolean,
    /**
     * Stretch to fill the container.
     */
    modVStretched: Boolean,
    /**
     * Items are placed at the start of the cross axis.
     */
    modVTop: Boolean,
    /**
     * Items are placed at the center of the cross axis.
     */
    modVCenter: Boolean,
    /**
     * Items are placed at the end of the cross axis.
     */
    modVBottom: Boolean,
    /**
     * Items can break out of their container.
     */
    modWide: Boolean,
  },
  data() {
    return {
      prefix: 'vl-grid',
    };
  },
  computed: {
    classes() {
      return [
        this.prefix,
        {
          [`${this.prefix}--is-stacked`]: this.modStacked,
          [`${this.prefix}--is-stacked-large`]: this.modStackedLarge,
          [`${this.prefix}--is-stacked-small`]: this.modStackedSmall,
          [`${this.prefix}--no-gutter`]: this.modNoGutter,
          [`${this.prefix}--align-start`]: this.modStart,
          [`${this.prefix}--align-center`]: this.modCenter,
          [`${this.prefix}--align-end`]: this.modEnd,
          [`${this.prefix}--align-space-between`]: this.modSpaceBetween,
          [`${this.prefix}--align-space-around`]: this.modSpaceAround,
          [`${this.prefix}--v-stretched`]: this.modVStretched,
          [`${this.prefix}--v-center`]: this.modVCenter,
          [`${this.prefix}--v-top`]: this.modVTop,
          [`${this.prefix}--v-bottom`]: this.modVBottom,
          [`${this.prefix}--wide`]: this.modWide,
        },
      ];
    },
  },
};
