import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'dl',
    },
    modFullWidth: {
      type: Boolean,
      default: false,
    },
    modCollapsed: Boolean,
    modBordered: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-properties',
        {
          'vl-properties--full-width': this.modFullWidth,
          'vl-properties--collapsed': this.modCollapsed,
          'vl-properties--bordered': this.modBordered,
        },
      ];
    },
  },
};
