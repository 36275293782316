const VlUOffset = {
  bind: (el, obj) => {
    const baseClass = 'vl-u-offset';

    let modifiers = Object.keys(obj.modifiers);

    if (!modifiers.length) {
      modifiers = ['left'];
    }

    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      el.classList.add(`${baseClass}--${modifier}`);
    }
  },
};

export default VlUOffset;

export { VlUOffset };
