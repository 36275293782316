const VlUAlign = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-align';

    const modifiers = Object.keys(binding.modifiers);
    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      el.classList.add(`${baseClass}-${binding.arg}--${modifier}`);
    }
    if (!modifiers.length) {
      el.classList.add(`${baseClass}-${binding.arg ? binding.arg : 'left'}`);
    }
  },
};

export default VlUAlign;

export { VlUAlign };
