const VlUSticky = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-sticky';

    el.style.top = binding.value.top;
    el.style.right = binding.value.right;
    el.style.bottom = binding.value.bottom;
    el.style.left = binding.value.left;
    el.classList.add(`${baseClass}`);
  },
};

export default VlUSticky;

export { VlUSticky };
