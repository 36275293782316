import debounce from 'lodash/debounce';
import nlBE from './locale/nl-BE';
import frBE from './locale/fr-BE';
import enGB from './locale/en-GB';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
      'fr-BE': frBE,
      'en-GB': enGB,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    hashChange: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: Number,
      default: null,
    },
    modOverflowScroll: Boolean,
    modScrollToActive: Boolean,
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: this.activeTab || 0,
      activeTabLabel: '',
      isMobileNavOpen: false,
      overflowRight: false,
      overflowLeft: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-tabs',
        {
          'vl-tabs--overflow': this.modOverflowScroll,
          'vl-tabs--visible-overflow': !this.overflowRight && !this.overflowLeft && this.modOverflowScroll,
        },
      ];
    },
    wrapperClasses() {
      return [
        'vl-tabs__wrapper',
        {
          'vl-tabs__wrapper--overflow': this.modOverflowScroll,
          'vl-tabs__wrapper--overflow-right': this.overflowRight,
          'vl-tabs__wrapper--overflow-left': this.overflowLeft,
        },
      ];
    },
  },
  created() {
    this.tabs = this.$children;
  },
  watch: {
    hashChange: {
      handler(hashChange) {
        window.removeEventListener('hashchange', this.handleHashChange);
        if (hashChange) {
          window.addEventListener('hashchange', this.handleHashChange);
        }
      },
    },
    activeTab: {
      immediate: false,
      handler(activeTab) {
        this.selectTab(activeTab, this.hashChange);
      },
    },
  },
  mounted() {
    if (this.tabs.length) {
      this.selectTab(this.activeTabIndex, false);
    }
    if (this.hashChange) {
      if (this.getTabIndexByHash(window.location.hash) > -1) {
        this.selectTab(this.getTabIndexByHash(window.location.hash), false);
      }
    }

    if (this.modOverflowScroll) {
      this.debouncedOnScroll = debounce(this.onScroll, 75);
      this.debouncedOnWindowResize = debounce(this.onWindowResize, 200);
      this.$refs.tabs.addEventListener('scroll', this.debouncedOnScroll);
      window.addEventListener('resize', this.debouncedOnWindowResize);
      this.checkOverflow(this.$refs.tabs);
    }
  },
  updated() {
    if (this.modOverflowScroll) {
      this.checkOverflow(this.$refs.tabs);
    }
  },
  destroyed() {
    if (this.hashChange) {
      window.removeEventListener('hashchange', this.handleHashChange);
    }
    if (this.modOverflowScroll) {
      if (this.debouncedOnScroll && this.$refs.tabs) {
        this.$refs.tabs.removeEventListener('scroll', this.debouncedOnScroll);
      }
      if (this.debouncedOnWindowResize) {
        window.removeEventListener('resize', this.debouncedOnWindowResize);
      }
    }
  },
  methods: {
    getTabIndexByHash(hash) {
      return this.tabs.findIndex(tab => tab.hash === hash);
    },
    handleHashChange() {
      return this.selectTab(this.getTabIndexByHash(window.location.hash));
    },
    selectTab(selectedTabIndex, updateHash = true) {
      let newTabIndex = selectedTabIndex;

      if (newTabIndex > this.tabs.length - 1) {
        newTabIndex = 0;
      } else if (newTabIndex < 0) {
        newTabIndex = this.tabs.length - 1;
      }

      const selectedTab = this.tabs[newTabIndex];

      if (this.activeTabIndex !== newTabIndex) {
        this.$emit('tab-change', selectedTab);
      }

      if (!selectedTab) {
        return;
      }

      if (selectedTab.isDisabled) {
        return;
      }

      this.tabs.forEach((tab) => {
        const ntab = tab;
        ntab.isActive = (tab.hash === selectedTab.hash);
      });

      this.activeTabLabel = this.tabs[newTabIndex].header;
      this.activeTabIndex = newTabIndex;
      const tabElements = this.$refs['vl-tab'];

      if (tabElements) {
        tabElements[this.activeTabIndex].focus();
      }

      if (updateHash && this.hashChange) {
        window.location.hash = selectedTab.hash;
      }

      if (this.modScrollToActive) {
        this.$nextTick(() => {
          this.updateTabsNavPosition();
        });
      }
    },
    updateTabsNavPosition() {
      let xPos = 0;
      for (let i = 0; i < this.getTabs().length; i++) {
        const tabLink = this.getTabs()[i];
        if (tabLink.classList.contains('vl-tab--active')) {
          break;
        } else {
          xPos += tabLink.clientWidth;
        }
      }

      this.$refs.tabs.scrollTo({ left: xPos, behavior: 'smooth' });
    },
    getTabs() {
      return this.$refs.tabs.querySelectorAll('.vl-tab');
    },
    clickHandler(index) {
      this.selectTab(index, true);
    },
    keyupHandler(event) {
      switch (event.keyCode) {
        case 37: {
          this.selectTab(this.activeTabIndex - 1, true);
          break;
        }
        case 39: {
          this.selectTab(this.activeTabIndex + 1, true);
          break;
        }
        default:
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpen = !this.isMobileNavOpen;
    },
    onScroll(event) {
      this.checkOverflow(event.target);
    },
    onWindowResize() {
      this.checkOverflow(this.$refs.tabs);
    },
    checkOverflow(target) {
      const overflow = target.scrollWidth - target.offsetWidth;
      const delta = target.scrollLeft + target.offsetWidth;
      const noDeltaRight = delta === target.scrollWidth;
      const noDeltaLeft = delta === target.offsetWidth;

      // no overflow
      if ((target.scrollLeft <= 0 && (noDeltaLeft && noDeltaRight)) || overflow < 10) {
        this.overflowRight = false;
        this.overflowLeft = false;
        return;
      }

      // overflow
      if (noDeltaRight) { // overflow left
        this.overflowLeft = true;
        this.overflowRight = false;
      } else if (noDeltaLeft) { // overflow right
        this.overflowRight = true;
        this.overflowLeft = false;
      } else { // overflow left & right
        this.overflowRight = true;
        this.overflowLeft = true;
      }
    },
  },
};
