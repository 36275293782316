import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    label: {
      type: String,
      required: true,
    },
    modActive: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-progress-bar__step', {
          'vl-progress-bar__step--active': this.modActive,
        },
      ];
    },
    step() {
      return this.modActive ? 'step' : null;
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
  },
};
