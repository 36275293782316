import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue/';
import { VlTooltip } from '@govflanders/vl-ui-tooltip/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  directives: {
    VlTooltip,
  },
  components: {
    VlAnnotation,
  },
  inheritAttrs: false,
  props: {
    /**
     * Image caption
    */
    caption: String,
    /**
    * Image copyright
    */
    copyright: String,
    /**
    * Display as a discreet copyright button with a tooltip containing copyright notice
    */
    modDiscreet: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-caption',
        {
          'vl-caption--discreet': this.modDiscreet,
        },
      ];
    },
  },
};
