export default
{
  // translation: 'vertaling',
  validation: {
    messages: {
      _default: '{_field_} is ongeldig.',
      alpha: '{_field_} mag alleen letters bevatten',
      alpha_dash: '{_field_} mag alleen letters, cijfers en streepjes bevatten.',
      alpha_num: '{_field_} mag alleen letters en nummers bevatten',
      alpha_spaces: '{_field_} mag alleen letters en spaties bevatten',
      between: '{_field_} moet tussen {min} en {max} liggen',
      confirmed: '{_field_} komt niet overeen met het bevestigingsveld',
      digits: '{_field_} moet een nummer zijn en exact {length} tekens bevatten',
      dimensions: '{_field_} moet {width} pixels breed zijn en {height} pixels hoog',
      email: '{_field_} moet een geldig e-mailadres zijn',
      excluded: '{_field_} is ongeldig',
      ext: '{_field_} moet een geldig bestand zijn',
      image: '{_field_} moet een afbeelding zijn',
      oneOf: '{_field_} moet een geldige waarde zijn',
      max: '{_field_} mag niet groter zijn dan {length} karakters',
      max_value: '{_field_} mag maximaal {max} zijn',
      mimes: '{_field_} moet een geldig bestandstype hebben',
      min: '{_field_} moet minimaal {length} karakters zijn',
      min_value: '{_field_} moet minimaal {min} zijn',
      numeric: '{_field_} mag alleen nummers bevatten',
      regex: '{_field_} heeft een ongeldig formaat',
      required: '{_field_} is verplicht',
      required_if: '{_field_} is verplicht',
      size: '{_field_} mag niet groter zijn dan {size}KB',
      rrn: '{_field_} is een ongeldig rijksregisternummer',
      iban: '{_field_} is ongeldig',
      phone: '{_field_} is ongeldig',
      url: '{_field_} is ongeldige url',
    },
  },
};
