import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    modNoBorder: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-link-list__item',
        this.modNoBorder ? 'vl-link-list__item--no-border' : false,
      ];
    },
  },
};
