import VlAccordion from './vl-accordion.vue';
import VlAccordionList from './subcomponents/vl-accordion-list.vue';
import VlAccordionListItem from './subcomponents/vl-accordion-list-item.vue';

export default VlAccordion;

export {
  VlAccordion,
  VlAccordionList,
  VlAccordionListItem,
};
