import VlLink from '@govflanders/vl-ui-link/src/vue/';
import nlBE from '../locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlLink,
  },
  props: {
    modActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useSeparator: true,
      isLastItem: false,
      lastItemActive: false,
      isCondensed: false,
      isThirdItem: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-breadcrumb__list__item',
        {
          'vl-breadcrumb__list__item--deactivated': this.isLastItem && !this.lastItemActive,
          'vl-u-hidden': this.isCondensed && !this.isThirdItem,
        },
      ];
    },
    ctaClasses() {
      return [
        'vl-breadcrumb__list__item__cta',
        {
          'vl-breadcrumb__list__item__cta--bold': this.$parent.modBold,
        },
      ];
    },
    isBold() {
      return this.$parent.modBold;
    },
    page() {
      return this.modActive ? 'page' : null;
    },
  },
  methods: {
    removeCondensed() {
      this.$emit('removeCondensed');
    },
  },
};
