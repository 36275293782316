const noJsClass = 'no-js',
  jSclass = 'js';

// Remove no-js class, add js class
const _jsDetection = () => {
  if (vl.util.hasClass(document.documentElement, noJsClass)) {
    vl.util.removeClass(document.documentElement, noJsClass);
  }

  vl.util.addClass(document.documentElement, jSclass);
};

_jsDetection();
