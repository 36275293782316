import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'label',
    },
    id: {
      type: String,
      required: true,
    },
    modSingle: Boolean,
    modBlock: Boolean,
    modDisabled: Boolean,
    modError: Boolean,
    modSuccess: Boolean,
    value: null,
    checked: null,
  },
  model: {
    prop: 'checked',
    event: 'input',
  },
  computed: {
    classes() {
      return [
        'vl-radio',
        {
          'vl-radio--block': this.modBlock,
          'vl-radio--single': this.modSingle,
          'vl-radio--disabled': this.modDisabled,
          'vl-radio--error': this.modError,
          'vl-radio--success': this.modSuccess,
        },
      ];
    },
    labelClasses() {
      return [
        'vl-radio__label',
      ];
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
    computedValue: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
