const VlUJsCheck = {
  bind: (el) => {
    el.classList.remove('no-js');
    el.classList.add('js');
  },
};

export default VlUJsCheck;

export { VlUJsCheck };
