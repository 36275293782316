import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    prefix: String,
    title: String,
    href: String,
    modSmall: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-content-header__entity-logo',
        this.modSmall ? 'vl-content-header__entity-logo--small' : false,
      ];
    },
    tagName() {
      return this.href ? 'a' : 'div';
    },
  },
};
