import VlCaption from '@govflanders/vl-ui-caption/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlCaption,
  },
  inheritAttrs: false,
  props: {
    /**
     * Image caption
    */
    caption: String,
    /**
     * Image copyright
    */
    copyright: String,
    /**
     * Float image to the left
    */
    modLeft: Boolean,
    /**
     * Float image to the right
    */
    modRight: Boolean,
    /**
     * Enables native lazyload: https://web.dev/native-lazy-loading/
    */
    modLazy: String,
    /**
     * Enabled full width display
     */
    modFullWidth: Boolean,
  },
  computed: {
    classes() {
      return {
        'vl-image': true,
        'vl-image--full-width': this.modFullWidth,
      };
    },
    alignValue() {
      if (this.modLeft) {
        return 'left';
      }
      if (this.modRight) {
        return 'right';
      }
      return null;
    },
    lazyLoadAttr() {
      return this.modLazy || null;
    },
  },
};
