import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'span',
    },
    modSmall: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-annotation',
        {
          'vl-annotation--small': this.modSmall,
        },
      ];
    },
  },
};
