import nlBE from '../../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    total: {
      type: String,
      required: true,
    },
    prefix: String,
    pageLabel: String,
  },
  computed: {
    classes() {
      return 'vl-pager__element';
    },
  },
};
