export default
{
  uploadFilesHiddenCloseLabel: 'Verwijder alle bestanden',
  uploadDragText: 'Sleep de bijlage naar hier om ze toe te voegen.',
  uploadLabel: 'Bijlage toevoegen',
  uploadMaxFileSize: 'Het bestand mag maximaal {size} zijn.',
  uploadInvalidFileType: 'Dit bestandstype is niet toegestaan.',
  uploadResponseError: 'Er liep iets fout bij het uploaden.',
  uploadMaxFilesExceeded: 'Je kan maximaal {maxFiles} bestand(en) uploaden.',
};
