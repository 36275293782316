let Select;

if (typeof window !== 'undefined') {
  Select = require('ol/interaction/Select').default; /* eslint-disable-line */
}

export default {
  data() {
    return {
      select: undefined,
      style: undefined,
    };
  },
  computed: {
    map() {
      return this.$parent.map;
    },
  },
  watch: {
    map(map) {
      map.addInteraction(this.select);
      this.select.on('select', (e) => {
        e.selected.flatMap(feature => feature.get('features') || feature).forEach(feature => feature.set('selected', true));
        e.deselected.flatMap(feature => feature.get('features') || feature).forEach(feature => feature.set('selected', false));
        this.$emit('select', e);
      });
    },
  },
  mounted() {
    if (!this.select && Select) {
      this.select = new Select({
        multi: true,
        layers: (layer) => layer === this.$parent.layer,
        style: this.style ? (feature) => {
          try {
            return this.style(feature);
          } catch {
            return this.style;
          }
        } : undefined,
      });
    }
  },
};
