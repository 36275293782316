import { VlImage } from '@govflanders/vl-ui-image/src/vue/';
import { VlLayout } from '@govflanders/vl-ui-core/src/vue';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'header',
    },
    background: {
      src: String,
      srcset: String,
      alt: String,
    },
    modLarge: Boolean,
    modLogo: Boolean,
    modShowMobile: Boolean,
    modAlt: Boolean,
    modContext: Boolean,
    modActions: Boolean,
  },
  components: {
    VlImage,
    VlLayout,
  },
  computed: {
    classes() {
      return [
        'vl-content-header',
        this.modLarge ? 'vl-content-header--large' : false,
        this.modLogo ? 'vl-content-header--has-logo' : false,
        this.modShowMobile ? 'vl-content-header--show-mobile' : false,
        this.modAlt ? 'vl-content-header--alt' : false,
        this.modActions ? 'vl-content-header--has-actions' : false,
        this.modContext ? 'vl-content-header--has-context' : false,
      ];
    },
  },
};
