import { VlImage } from '@govflanders/vl-ui-image/src/vue';
import { VlCaption } from '@govflanders/vl-ui-caption/src/vue';
import { VlLink } from '@govflanders/vl-ui-link/src/vue';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlImage,
    VlCaption,
    VlLink,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    image: {
      type: Object,
    },
    imageFallback: {
      type: String,
    },
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    modSmall: Boolean,
    modHorizontal: Boolean,
    modAnnotation: Boolean,
    modNameSmall: Boolean,
    modAlignLeft: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-person',
        {
          'vl-person--small': this.modSmall,
          'vl-person--horizontal': this.modHorizontal,
          'vl-person--annotation': this.modAnnotation,
          'vl-person--name-small': this.modNameSmall,
          'vl-person--align-left': this.modAlignLeft,
        },
      ];
    },
    hasLink() {
      return this.href || this.to;
    },
    initials() {
      if (this.name) {
        let initials = '';
        const names = this.name.split(' ').slice(0, 3);
        for (let n = 0; n < names.length; n += 1) {
          initials += names[n].substring(0, 1).toUpperCase();
        }
        return initials;
      }

      return '';
    },
  },
};
