import Multiselect from 'vue-multiselect';
import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Enables/disables multiple options to be selected
    */
    modMultiple: {
      type: Boolean,
      default: true,
    },
    /**
     * Enables error state
    */
    modError: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables success state
    */
    modSuccess: {
      type: Boolean,
      default: false,
    },
    /**
     * Boolean to toggle disabled state
    */
    modDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * String to show when pointing to an option
    */
    selectLabel: String,
    /**
     * String to show when pointing to an option
    */
    selectGroupLabel: String,
    /**
     * String to show next to selected option
    */
    selectedLabel: String,
    /**
     * String to show when pointing to an already selected option
    */
    deselectLabel: String,
    /**
     * String to show when pointing to an already selected option
    */
    deselectGroupLabel: String,
    /**
     * Variable to hold the selected option
     */
    value: null,
  },
  components: {
    Multiselect,
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-multiselect', {
          'vl-multiselect--single': !this.modMultiple,
          'vl-multiselect--error': this.modError,
          'vl-multiselect--success': this.modSuccess,
        },
      ];
    },
    computedPlaceholder() {
      return this.$attrs && this.$attrs.placeholder ? this.$attrs.placeholder : this.$t('placeholder');
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    computedSelectLabel() {
      return this.selectLabel || this.$t('selectLabel');
    },
    computedSelectGroupLabel() {
      return this.selectGroupLabel || this.$t('selectGroupLabel');
    },
    computedSelectedlabel() {
      return this.selectedLabel || this.$t('selectedLabel');
    },
    computedDeselectLabel() {
      return this.deselectLabel || this.$t('deselectLabel');
    },
    computedDeselectGroupLabel() {
      return this.deselectGroupLabel || this.$t('deselectGroupLabel');
    },
  },
};
