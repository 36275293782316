export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    title: String,
    modAlt: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-share-buttons', {
          'vl-share-buttons--alt': this.modAlt,
        },
      ];
    },
  },
};
