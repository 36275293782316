const VlUFlex = {
  bind: (el, obj) => {
    const baseClass = 'vl-u-flex';
    el.classList.add(baseClass);
    const modifiers = Object.keys(obj.modifiers);
    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      el.classList.add(`${baseClass}-${obj.arg}--${modifier}`);
    }
    if (modifiers.length < 1 && typeof obj.arg !== 'undefined') {
      el.classList.add(`${baseClass}-${obj.arg}`);
    }
  },
};

export default VlUFlex;

export { VlUFlex };
