import VlLoader from '@govflanders/vl-ui-loader/src/vue';
import nlBE from './locale/nl-BE';

export default {
  components: {
    VlLoader,
  },
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modOverlay: Boolean,
    modBlocking: Boolean,
    title: String,
  },
  computed: {
    classes() {
      return [
        'vl-ol-map-loader',
        {
          'vl-ol-map-loader--blocking': this.modBlocking,
          'vl-ol-map-loader--overlay': this.modOverlay,
        },
      ];
    },
  },
};
