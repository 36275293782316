import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    icon: String,
    title: String,
    modLarge: Boolean,
    modMedium: Boolean,
    modVCenter: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-info-tile',
        'vl-info-tile--center', {
          'vl-info-tile--l': this.modLarge,
          'vl-info-tile--m': this.modMedium,
          'vl-info-tile--v-center': this.modVCenter,
        },
      ];
    },
    defaultSlotFilled() {
      return !!this.$slots.default;
    },
    footerSlotFilled() {
      return !!this.$slots.footer;
    },
  },
};
