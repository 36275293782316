import { VlFormMessageLabel } from '@govflanders/vl-ui-form-message/src/vue';
import VlButton from '@govflanders/vl-ui-button/src/vue';
import VlInputField from '@govflanders/vl-ui-input-field/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlFormMessageLabel,
    VlButton,
    VlInputField,
  },
  props: {
    fromId: {
      type: String,
      required: true,
    },
    fromValue: {
      type: [String, Number],
      default: 0,
    },
    fromLabel: String,
    fromHandleLabel: String,
    toId: {
      type: String,
      required: true,
    },
    toValue: {
      type: [String, Number],
      default: 100,
    },
    toLabel: String,
    toHandleLabel: String,
  },
  data() {
    return {
      minPos: 0,
      maxPos: 0,
      valueNow: 50,
      railMin: 0,
      railMax: 100,
      railWidth: 0,
      railBorderWidth: 0,
      thumbWidth: 15,
      keyCode: {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        pageUp: 33,
        pageDown: 34,
        end: 35,
        home: 36,
      },
      sliderRangeStyle: {
        width: 0,
        left: 0,
      },
      fromHandleStyle: {
        transform: 'translateX(0px}',
      },
      toHandleStyle: {
        transform: 'translateX(0px}',
      },
      handleFromValues: {
        min: Number(this.fromValue),
        max: Number(this.toValue),
        valueNow: Number(this.fromValue),
      },
      handleToValues: {
        min: Number(this.fromValue),
        max: Number(this.toValue),
        valueNow: Number(this.toValue),
      },
    };
  },
  watch: {
    'handleFromValues.valueNow': function () {
      this.$emit('change', {
        from: this.handleFromValues.valueNow,
        to: this.handleToValues.valueNow,
      });
    },
    'handleToValues.valueNow': function () {
      this.$emit('change', {
        from: this.handleFromValues.valueNow,
        to: this.handleToValues.valueNow,
      });
    },
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-range',
      ];
    },
    fromText() {
      return this.fromLabel ? this.fromLabel : this.$i18n.t('range.fromLabel');
    },
    fromTextHandle() {
      return this.fromHandleLabel ? this.fromHandleLabel : this.$i18n.t('range.fromHandleLabel');
    },
    toText() {
      return this.toLabel ? this.toLabel : this.$i18n.t('range.toLabel');
    },
    toTextHandle() {
      return this.toHandleLabel ? this.toHandleLabel : this.$i18n.t('range.toHandleLabel');
    },

  },
  // Lifecycle hooks
  mounted() {
    this.railMin = Number(this.fromValue);
    this.railMax = Number(this.toValue);
    this.railWidth = this.$refs.rangeSlider.clientWidth;
    this.fromHandle = this.$refs.fromHandle;
    this.toHandle = this.$refs.toHandle;

    this.moveSliderTo(this.fromHandle, this.handleFromValues.min);
    this.moveSliderTo(this.toHandle, this.handleToValues.max);

    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  // Methods
  methods: {
    onInputMin(value) {
      this.moveSliderTo(this.fromHandle.$el, Number(value));
    },

    onInputMax(value) {
      this.moveSliderTo(this.toHandle.$el, Number(value));
    },

    handleResize() {
      this.railWidth = this.$refs.rangeSlider.clientWidth;
      this.moveSliderTo(this.fromHandle, this.handleFromValues.valueNow);
      this.moveSliderTo(this.toHandle, this.handleToValues.valueNow);
    },

    handleKeyDown(event) {
      let flag = false;
      const currentHandleEl = event.target;

      if (currentHandleEl === this.fromHandle.$el) {
        this.valueNow = this.handleFromValues.valueNow;
      } else if (currentHandleEl === this.toHandle.$el) {
        this.valueNow = this.handleToValues.valueNow;
      }

      switch (event.keyCode) {
        case this.keyCode.left:
        case this.keyCode.down:
          this.handleDownLeft(currentHandleEl);
          flag = true;
          break;

        case this.keyCode.right:
        case this.keyCode.up:
          this.handleRightUp(currentHandleEl);
          flag = true;
          break;

        case this.keyCode.pageDown:
          this.handlePageDown(currentHandleEl);
          flag = true;
          break;

        case this.keyCode.pageUp:
          this.handlePageUp(currentHandleEl);
          flag = true;
          break;

        case this.keyCode.home:
          this.handleHome(currentHandleEl);
          flag = true;
          break;

        case this.keyCode.end:
          this.handleEnd(currentHandleEl);
          flag = true;
          break;

        default:
          break;
      }

      if (flag) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    handleDownLeft(currentHandleEl) {
      this.moveSliderTo(currentHandleEl, this.valueNow - 1);
    },

    handleRightUp(currentHandleEl) {
      this.moveSliderTo(currentHandleEl, this.valueNow + 1);
    },

    handlePageDown(currentHandleEl) {
      this.moveSliderTo(currentHandleEl, this.valueNow - 10);
    },

    handlePageUp(currentHandleEl) {
      this.moveSliderTo(currentHandleEl, this.valueNow + 10);
    },

    handleHome(currentHandleEl) {
      this.moveSliderTo(currentHandleEl, this.railMin);
    },

    handleEnd(currentHandleEl) {
      this.moveSliderTo(currentHandleEl, this.railMax);
    },

    handleMouseDown(event) {
      const currentHandleEl = event.target;

      const handleMouseMove = (e) => {
        const diffX = e.pageX - this.$refs.rangeSlider.getBoundingClientRect().left;

        this.valueNow = this.railMin + parseInt((this.railMax - this.railMin) * diffX / this.railWidth, 10);
        this.moveSliderTo(currentHandleEl, this.valueNow);

        e.preventDefault();
        e.stopPropagation();
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchmove', handleMouseMove);
        document.removeEventListener('touchend', handleMouseUp);
      };

      // bind a mousemove event handler to move pointer
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchmove', handleMouseMove);

      // bind a mouseup event handler to stop tracking mouse movements
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchend', handleMouseUp);

      event.preventDefault();
      event.stopPropagation();

      // Set focus to the clicked handle
      currentHandleEl.focus();
    },

    moveSliderTo(handleEl, value) {
      if (handleEl === this.fromHandle.$el) {
        if (value > this.handleFromValues.max) {
          value = this.handleFromValues.max;
        }

        if (value < this.handleFromValues.min) {
          value = this.handleFromValues.min;
        }
      } else if (handleEl === this.toHandle.$el) {
        if (value > this.handleToValues.max) {
          value = this.handleToValues.max;
        }

        if (value < this.handleToValues.min) {
          value = this.handleToValues.min;
        }
      }

      this.valueNow = value;
      this.valueNowText = value;

      if (handleEl === this.fromHandle.$el) {
        this.handleFromValues.valueNow = this.valueNow;
      }

      if (handleEl === this.toHandle.$el) {
        this.handleToValues.valueNow = this.valueNow;
      }

      const railLength = (this.railWidth - 2 * (this.thumbWidth - this.railBorderWidth));
      const pos = Math.round((this.valueNow - this.railMin) * railLength / (this.railMax - this.railMin));

      if (handleEl === this.fromHandle.$el) {
        this.minPos = pos;
        this.handleToValues.min = this.valueNow;
        this.fromHandleStyle.transform = `translateX(${pos - this.railBorderWidth + 'px'})`;
      } else {
        this.maxPos = pos;
        this.handleFromValues.max = this.valueNow;

        this.toHandleStyle.transform = `translateX(${pos + this.thumbWidth - this.railBorderWidth + 'px'})`;
      }

      const rangeWidthPerc = (this.maxPos - this.minPos) / this.railWidth * 100;

      this.sliderRangeStyle.width = `${rangeWidthPerc + '%'}`;
      const rangeLeftPosPerc = (this.minPos + this.thumbWidth) / this.railWidth * 100;
      this.sliderRangeStyle.left = `${rangeLeftPosPerc + '%'}`;
    },
  },
};
