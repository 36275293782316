import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlButton from '@govflanders/vl-ui-button/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlButton,
    VlLink,
  },
  props: {
    tagName: {
      type: String,
      default: 'a',
    },
    icon: String,
    label: String,
    modSmall: Boolean,
    modDisabled: Boolean,
    modActive: Boolean,
    modNoBorder: Boolean,
  },
  data() {
    return {
      isActive: this.modActive,
    };
  },
  computed: {
    classes() {
      return [
        'vl-tile-navigation-item',
        {
          'vl-tile-navigation-item--active': this.isActive,
          'vl-tile-navigation-item--disabled': this.modDisabled,
          'vl-tile-navigation-item--no-border': this.modNoBorder,
        },
      ];
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
  },
  beforeCreate() { },
  created() { },
  beforeMount() { },
  mounted() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  watch: {
  },
  methods: {
  },
};
