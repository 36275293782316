import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modVertical: Boolean,
    modSingle: Boolean,
    modMultiple: Boolean,
  },
  data() {
    return {
      activeTools: [],
      value: null,
    };
  },

  computed: {
    classes() {
      return [
        'vl-ol-map-tools-action-group', {
          'vl-ol-map-tools-action-group--vertical': this.modVertical,
        },
      ];
    },
  },
  mounted() {
    // register initial active childs
    for (let i = 0; i < this.$children.length; i++) {
      if (this.$children[i].isActive) {
        this.setToolActive(this.$children[i]);
      }
    }

    if (this.modMultiple || this.modSingle) {
      for (let i = 0; i < this.$children.length; i++) {
        const child = this.$children[i];
        if (child.classes.indexOf('vl-ol-map-tools-action') >= 0) {
          child.$on('change', () => {
            if (this.modSingle && this.activeTools.length > 0) {
              for (let k = 0; k < this.$children.length; k++) {
                if (this.$children[k].id !== child.id) {
                  this.setToolInactive(this.$children[k]);
                }
              }
            }
            this.toggleTool(child);
          });
        }
      }
    }
  },
  watch: {
    activeTools() {
      this.$emit('input', this.activeTools);
    },
  },
  methods: {
    setToolActive(tool) {
      this.activeTools.push(tool.id);
      tool.isActive = true;
    },
    setToolInactive(tool) {
      for (let i = 0; i < this.$children.length; i++) {
        const child = this.$children[i];
        if (child.id === tool.id) {
          for (let j = 0; j < this.activeTools.length; j++) {
            if (this.activeTools[j] === tool.id) {
              this.activeTools.splice(j, 1);
            }
          }
          child.isActive = false;
        }
      }
      tool.isActive = false;
    },
    toggleTool(tool) {
      if (tool.isActive) {
        this.setToolInactive(tool);
      } else {
        this.setToolActive(tool);
      }
    },
    getToolById(toolId) {
      for (let i = 0; i < this.$children.length; i++) {
        const child = this.$children[i];
        if (child.id === toolId) {
          return child;
        }
      }
      return null;
    },
  },
};
