import VlButton from '@govflanders/vl-ui-button/src/vue';
import VlIcon from '@govflanders/vl-ui-icon/src/vue';

import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import nlBE from './locale/nl-BE';

Vue.use(Vue2TouchEvents);

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlButton,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    id: String,
    modFlyout: Boolean,
    modCollapsible: Boolean,
    modOpened: Boolean,
    modRight: Boolean,
    labelCloseAria: String,
    a11yLabel: String,
  },
  data() {
    return {
      opened: this.modOpened,
      isClosing: false,
      activeArray: [],
      backdropClass: 'vl-backdrop',
      backdropActiveClass: 'vl-backdrop--active',
    };
  },
  computed: {
    classes() {
      return [
        'vl-sidebar', {
          'vl-sidebar--collapsible': this.modCollapsible,
          'vl-sidebar--flyout': this.modFlyout,
          'vl-sidebar--right': this.modRight,
          'vl-sidebar--opened': this.opened,
          'vl-sidebar--closing': this.closing,
        },
      ];
    },
    icon() {
      if (!this.modRight) {
        return this.opened ? 'nav-left' : 'nav-right';
      }

      return this.opened ? 'nav-right' : 'nav-left';
    },
    sidebarA11yLabel() {
      return this.a11yLabel || this.$t('sidebarA11yLabel');
    },
  },
  provide() {
    return {
      activeArray: this.activeArray,
    };
  },
  methods: {
    toggle(event, force) {
      this.opened = force || !this.opened;
      this.backdrop = document.querySelector(`.${this.backdropClass}`);
      if (this.opened) {
        if (!this.backdrop) {
          this.backdrop = document.createElement('div');
          this.backdrop.classList.add(this.backdropClass);
          document.body.appendChild(this.backdrop);
        }
        this.backdrop.classList.add(this.backdropActiveClass);
        this.backdrop.addEventListener('click', this.toggleBackdrop);
      } else {
        this.closing = true;
        if (this.backdrop) {
          this.backdrop.classList.remove(this.backdropActiveClass);
        }
        setTimeout(() => {
          this.closing = false;
        }, 500);
      }

      this.$emit('sidebar.toggle', this.opened);
    },
    swipe(direction) {
      if (this.modCollapsible) {
        switch (direction) {
          case 'left':
            if (this.modRight) {
              this.toggle(null, true);
            } else {
              this.toggle(null, false);
            }
            break;
          case 'right':
            if (this.modRight) {
              this.toggle(null, false);
            } else {
              this.toggle(null, true);
            }
            break;
          default:
            break;
        }
      }
    },
    toggleBackdrop() {
      this.toggle(null, false);
    },
    beforeDestroy() {
      if (this.backdrop) {
        this.backdrop.removeEventListener('click', this.toggleBackdrop);
      }
    },
  },
};
