window.vl = window.vl || {ns: 'vl-'};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
const debounce = (func, wait, immediate = false) => {
  let timeout;

  return function() {
    let context = this,
      args = arguments,
      later,
      callNow;

    later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    callNow = immediate && !timeout;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

const throttle = (func, threshhold = 250, scope) => {
  let last, deferTimer;

  return function() {
    let context = scope || this,
      now = Number(new Date()),
      args = arguments;

    if (last && now < last + threshhold) {
      // hold on to it
      window.clearTimeout(deferTimer);
      deferTimer = window.setTimeout(() => {
        last = now;
        func.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      func.apply(context, args);
    }
  };
};

const addClass = (el, classes) => {
  el.classList.add(classes);
};

const removeClass = (el, classes) => {
  el.classList.remove(classes);
};

const hasClass = (el, classes) => {
  return el.classList.contains(classes);
};

const toggleClass = (el, classes) => {
  el.classList.toggle(classes);
};

const addClassFor = (el, classes, duration) => {
  addClass(el, classes);

  window.setTimeout(() => {
    removeClass(el, classes);
  }, duration);
};

// Helper function that takes an HTML string & an object to use for
// "binding" its properties to the HTML template above.
const parseTemplate = (str, data) => {
  return str.replace(/\$\{(\w+)\}/gi, (match, parensMatch) => {
    if (typeof data[parensMatch] !== 'undefined') {
      return data[parensMatch];
    }
    return match;
  });
};

// trigger a custom event on an object
const triggerEvent = (obj, evt) => {
  let fireOnThis = obj,
    evtObj;

  if (document.createEvent) {
    evtObj = document.createEvent('MouseEvents');

    evtObj.initEvent(evt, true, false);
    fireOnThis.dispatchEvent(evtObj);
  } else if (document.createEventObject) {
    evtObj = document.createEventObject();

    fireOnThis.fireEvent(`on${evt}`, evtObj);
  }
};

const unique = array => {
  return array.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
};

const closest = (value, to) => {
  return Math.round(value / to) * to;
};

// Current position of an element relative to the document.
const offset = el => {
  let rect = el.getBoundingClientRect(),
    doc = el.ownerDocument,
    win = doc.defaultView || doc.parentWindow,
    docElem = doc.documentElement,
    xOff = win.pageXOffset;

  // getBoundingClientRect contains left scroll in Chrome on Android.
  // I haven't found a feature detection that proves this. Worst case
  // scenario on mis-match: the 'tap' feature on horizontal sliders breaks.
  if (/webkit.*Chrome.*Mobile/i.test(navigator.userAgent)) {
    xOff = 0;
  }

  return {
    top: rect.top + win.pageYOffset - docElem.clientTop,
    left: rect.left + xOff - docElem.clientLeft
  };
};

// Insert after
const insertAfter = (newElement, targetElement) => {
  let parent = targetElement.parentNode;

  // If targetElement is the parents last-child
  if (parent.lastchild === targetElement) {
    // Add the newElement after the target element
    parent.appendChild(newElement);
  } else {
    // Target has siblings, insert the new element between the target and its next sibling
    parent.insertBefore(newElement, targetElement.nextSibling);
  }
};

const removeElement = targetElement => {
  let parent = targetElement.parentNode;

  parent.removeChild(targetElement);
};

const isNumeric = number => {
  return !isNaN(parseFloat(number)) && isFinite(number);
};

const wrap = (el, wrapper) => {
  // Cache the current parent and sibling
  let parent = el.parentNode,
    sibling = el.nextSibling;

  wrapper.appendChild(el);

  /**
   * If the element had a sibling, insert the wrapper before
   * the sibling to maintain the HTML structure; otherwise, just
   * append it to the parent.
   */
  if (sibling) {
    parent.insertBefore(wrapper, sibling);
  } else {
    parent.appendChild(wrapper);
  }
};

// Strip tags from element
const stripTags = html => {
  let tmp = document.createElement('div');

  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText;
};

// Create a unique ID
const uniqueId = () => {
  // Desired length of Id
  // Always start with a letter -- base 36 makes for a nice shortcut
  let idStrLen = 32,
    idStr = `${(Math.floor(Math.random() * 25) + 10).toString(36)}_`;

  // Add a timestamp in milliseconds (base 36 again) as the base
  idStr += `${new Date().getTime().toString(36)}_`;

  // Similar to above, complete the Id using random, alphanumeric characters
  do {
    idStr += Math.floor(Math.random() * 35).toString(36);
  } while (idStr.length < idStrLen);

  return idStr;
};

// Create a unique name
const uniqueName = () => {
  return Math.random()
    .toString(36)
    .substring(2, 5);
};

// Rounds a number to 7 supported decimals
const accurateNumber = number => {
  let p = Math.pow(10, 7);

  return Number((Math.round(number * p) / p).toFixed(7));
};

// Limits a value to 0 - 100
const limit = a => {
  return Math.max(Math.min(a, 100), 0);
};

// Wraps a variable as an array, if it isn't one yet.
const asArray = a => {
  return Array.isArray(a) ? a : [a];
};

// Count decimals
const countDecimals = numStr => {
  let pieces = numStr.split('.');

  return pieces.length > 0 ? pieces[1].length : 0;
};

// Scroll element to specific position
// TODO: use element.scrollIntoView instead, maybe with fallback if you want old browser to have a smooth scroll
const scrollTo = (el, to, duration, callback = () => {}) => {
  let scrollTop = el.scrollTop,
    difference = to - scrollTop,
    perTick;

  if (duration < 0) {
    return;
  }

  perTick = difference / duration * 10;

  window.setTimeout(() => {
    if (scrollTop === to || duration <= 0) {
      callback();
    } else {
      el.scrollTop = scrollTop + perTick;
      scrollTo(el, to, duration - 10, callback);
    }
  }, 10);
};

// Gets a random number between min and max
const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// Much faster each loop than a forEach loop
const each = (arr, fn) => {
  let l = arr.length,
    i = 0;

  for (; i < l; i++) {
    fn(arr[i], i);
  }
};

/**
 * Determines if a certain value exists, is not null, and not empty
 * @method exists
 * @param  {type}  value value to check
 * @param  {Boolean} [nullAllowed = false] optional parameter to allow null
 * @param  {Boolean} [emptyAllowed = false] optional paramet to allow empty values
 * @return {Boolean}
 */
const exists = (value, nullAllowed = false, emptyAllowed = false) => {
  if (typeof value !== 'undefined') {
    if (nullAllowed || value !== null) {
      if (emptyAllowed || value !== '') {
        return true;
      }
    }
  }

  return false;
};

const bytesToSize = (bytes, addUnits = true, base = 1024) => {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
    value = null,
    i;

  if (bytes === 0) {
    value = addUnits ? '0 bytes' : 0;
  } else {
    i = parseInt(Math.floor(Math.log(bytes) / Math.log(base)), 10);

    if (!addUnits) {
      value =  Math.round(bytes / Math.pow(base, i), 2);
    }

    value = addUnits ? Math.round(bytes / Math.pow(base, i), 2) + ' ' + sizes[i] : Math.round(bytes / Math.pow(base, i), 2);
  }

  return value;
};

const getJson = (url, callback) => {
  let xhr = new XMLHttpRequest();

  xhr.open('get', url, true);
  xhr.responseType = 'json';
  xhr.onload = function() {
    let status = xhr.status;

    if (status === 200) {
      callback(null, xhr.response);
    } else {
      callback(status);
    }
  };
  xhr.send();
};

/**
 * Get all DOM element up the tree that contain a class, ID, or data attribute
 * @param  {Node} elem The base element
 * @param  {String} selector The class, id, data attribute, or tag to look for
 * @return {Array} Null if no match
 */
const getParents = (elem, selector) => {
  let parents = [],
    firstChar;

  if (selector) {
    firstChar = selector.charAt(0);
  }

  // Get matches
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      // If selector is a class
      if (firstChar === '.') {
        if (elem.classList.contains(selector.substr(1))) {
          parents.push(elem);
        }
      }

      // If selector is an ID
      if (firstChar === '#') {
        if (elem.id === selector.substr(1)) {
          parents.push(elem);
        }
      }

      // If selector is a data attribute
      if (firstChar === '[') {
        if (elem.hasAttribute(selector.substr(1, selector.length - 1))) {
          parents.push(elem);
        }
      }

      // If selector is a tag
      if (elem.tagName.toLowerCase() === selector) {
        parents.push(elem);
      }
    } else {
      parents.push(elem);
    }
  }

  // Return parents if any exist
  if (parents.length === 0) {
    return null;
  }
  return parents;
};

const getParentsUntil = (elem, parent, selector) => {
  let parents = [],
    parentType,
    selectorType;

  if (parent) {
    parentType = parent.charAt(0);
  }
  if (selector) {
    selectorType = selector.charAt(0);
  }

  // Get matches
  for (; elem && elem !== document; elem = elem.parentNode) {
    // Check if parent has been reached
    if (parent) {
      // If parent is a class
      if (parentType === '.') {
        if (elem.classList.contains(parent.substr(1))) {
          break;
        }
      }

      // If parent is an ID
      if (parentType === '#') {
        if (elem.id === parent.substr(1)) {
          break;
        }
      }

      // If parent is a data attribute
      if (parentType === '[') {
        if (elem.hasAttribute(parent.substr(1, parent.length - 1))) {
          break;
        }
      }

      // If parent is a tag
      if (elem.tagName.toLowerCase() === parent) {
        break;
      }
    }

    if (selector) {
      // If selector is a class
      if (selectorType === '.') {
        if (elem.classList.contains(selector.substr(1))) {
          parents.push(elem);
        }
      }

      // If selector is an ID
      if (selectorType === '#') {
        if (elem.id === selector.substr(1)) {
          parents.push(elem);
        }
      }

      // If selector is a data attribute
      if (selectorType === '[') {
        if (elem.hasAttribute(selector.substr(1, selector.length - 1))) {
          parents.push(elem);
        }
      }

      // If selector is a tag
      if (elem.tagName.toLowerCase() === selector) {
        parents.push(elem);
      }
    } else {
      parents.push(elem);
    }
  }

  // Return parents if any exist
  if (parents.length === 0) {
    return null;
  }
  return parents;
};

class Util {
  constructor() {
    this.addClass = addClass;
    this.removeClass = removeClass;
    this.hasClass = hasClass;
    this.toggleClass = toggleClass;
    this.addClassFor = addClassFor;
    this.parseTemplate = parseTemplate;
    this.triggerEvent = triggerEvent;
    this.unique = unique;
    this.closest = closest;
    this.offset = offset;
    this.insertAfter = insertAfter;
    this.removeElement = removeElement;
    this.isNumeric = isNumeric;
    this.wrap = wrap;
    this.stripTags = stripTags;
    this.uniqueId = uniqueId;
    this.uniqueName = uniqueName;
    this.accurateNumber = accurateNumber;
    this.limit = limit;
    this.asArray = asArray;
    this.countDecimals = countDecimals;
    this.scrollTo = scrollTo;
    this.randomIntFromInterval = randomIntFromInterval;
    this.debounce = debounce;
    this.throttle = throttle;
    this.each = each;
    this.exists = exists;
    this.bytesToSize = bytesToSize;
    this.getJson = getJson;
    this.getParents = getParents;
    this.getParentsUntil = getParentsUntil;
  }
}

vl.util = new Util();

// Export single utils
export default {
  addClass,
  removeClass,
  hasClass,
  toggleClass,
  addClassFor,
  parseTemplate,
  triggerEvent,
  unique,
  closest,
  offset,
  insertAfter,
  removeElement,
  isNumeric,
  wrap,
  stripTags,
  uniqueId,
  uniqueName,
  accurateNumber,
  limit,
  asArray,
  countDecimals,
  scrollTo,
  randomIntFromInterval,
  debounce,
  throttle,
  each,
  exists,
  bytesToSize,
  getJson,
  getParents,
  getParentsUntil
};
