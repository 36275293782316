import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'ul',
    },
  },
  data() {
    return {
      hasSubNav: false,
    };
  },
  // Computed properties
  computed: {
    classes() {
      return [
        {
          'vl-side-navigation__group': this.hasSubNav,
        },
      ];
    },
  },
  mounted() {
    this.hasSubNav = !!this.$el.querySelectorAll('.vl-side-navigation__toggle + *').length;
  },
};
