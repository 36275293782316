import { VlImage } from '@govflanders/vl-ui-image/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlImage,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    src: {
      type: String,
      required: true,
    },
    srcset: String,
    alt: String,
    link: String,
  },
  computed: {
    classes() {
      return [
        'vl-gallery__item',
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit('click-gallery-item');
    },
  },
};
