import { ValidationProvider } from 'vee-validate';

export default {
  extends: ValidationProvider,
  props: {
    mode: {
      type: String,
      default: 'passive',
    },
  },
};
