import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import vlLinkMixin from '@govflanders/vl-ui-link/src/vue/vl-link-mixin';
import vlButtonMixin from './vl-button-mixin';
import nlBE from './locale/nl-BE';

export default {
  mixins: [vlLinkMixin, vlButtonMixin],
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    /**
     * Show an accessibility text after the button
     */
    a11yLabel: {
      type: String,
      default: null,
    },
    /**
     * Give the button the looks of a link
     */
    modLink: Boolean,
    /**
     * Ellipse button label to one line
     */
    modEllipse: Boolean,
  },
  computed: {
    classes() {
      return this.modLink ? this.linkClasses : this.buttonClasses;
    },
    iconClasses() {
      return this.modLink ? this.linkIconClasses : this.buttonIconClasses;
    },
    labelClasses() {
      return this.modLink ? null : this.buttonLabelClasses;
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
  },
};
