import VlOlMapLayer from '../vl-ol-map-layer/vl-ol-map-layer.vue';

let TileLayer;

if (typeof window !== 'undefined') {
  TileLayer = require('ol/layer/Tile').default; /* eslint-disable-line */
}

export default {
  mixins: [VlOlMapLayer],
  mounted() {
    this.layer = new TileLayer();
  },
  watch: {
    source(source) {
      this.layer.setSource(source);
    },
  },
};
