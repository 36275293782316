import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    message: String,
    modDark: Boolean,
    modMessageHidden: {
      type: Boolean,
      default: false,
    },
    modInline: {
      type: Boolean,
      default: false,
    },
    modSmall: {
      type: Boolean,
      default: false,
    },
  },
  // Computed properties
  computed: {
    loaderClasses() {
      return [
        'vl-loader', {
          'vl-loader--light': this.modDark,
          'vl-loader--small': this.modSmall,
        },
      ];
    },
    classes() {
      return this.modInline ? 'vl-loader-alert--inline' : undefined;
    },
  },
};
