import Flatpickr from 'flatpickr';
import LabelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';

import Dutch from 'flatpickr/dist/l10n/nl.js';
import VlInputGroup from '@govflanders/vl-ui-input-group/src/vue';
import VlInputAddon from '@govflanders/vl-ui-input-addon/src/vue';
import VlInputField from '@govflanders/vl-ui-input-field/src/vue';
import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlInputField,
    VlInputAddon,
    VlInputGroup,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Value format
    */
    format: {
      type: String,
      default: 'd-m-Y',
    },
    /**
     * Visual format
    */
    visualFormat: {
      type: String,
      default: 'd.m.Y',
    },
    /**
     * Default value
    */
    value: {
      type: Array,
      default: () => [],
    },
    /**
     * Minimum input date
    */
    minDate: String,
    /**
     * Maximum input date
    */
    maxDate: String,
    /**
     * Locale - default: Dutch, nl | https://flatpickr.js.org/localization/
    */
    locale: Object,
    /**
     * Turn off datepicker
     */
    disableDate: Boolean,
    /**
     * Hides the day selection in calendar. Use it along with enableTime to create a time picker.
     */
    noCalendar: Boolean,
    /**
     * Error variant of datepicker
    */
    modError: Boolean,
    /**
     * Disabled variant of button
    */
    modDisabled: Boolean,
    /**
     * String or Date object defining default date value
    */
    defaultDate: null,
    /**
     * Error variant of button
    */
    defaultTime: null,
    /**
     * Enables time picker
    */
    enableTime: Boolean,
    /**
     * Set 24h time selection
     */
    timeTwentyfour: {
      type: Boolean,
      default: true,
    },
    /**
     * Minimum time value
     */
    minTime: String,
    /**
     * Maximum time value
     */
    maxTime: String,
    /**
     * visually hidden text for screenreaders
     */
    addonText: String,
    /**
     * String displayed in addon tooltip
     */
    addonTooltip: String,
    /**
     * Icon displayed in addon button
     */
    addonIcon: {
      type: String,
      default: 'calendar',
    },
    /**
     * function that expects a date string and must return a Date object
     */
    parseDate: Function,
    /**
     * Datepicker mode: "single", "multiple", or "range"
     */
    mode: {
      type: String,
      default: 'single',
    },
    /**
     * Mark today in the calendar
     */
    modMarkToday: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    let settings = {
      allowInput: true,
      dateFormat: this.format,
      altFormat: this.visualFormat,
      altInput: true,
      minDate: this.minDate,
      maxDate: this.maxDate,
      defaultDate: this.defaultDate,
      locale: Dutch.nl,
      clickOpens: false,
      wrap: true,
      disableDate: this.disableDate,
      enableTime: this.enableTime,
      noCalendar: this.disableDate,
      time_24hr: this.timeTwentyfour,
      minTime: this.minTime,
      maxTime: this.maxTime,
      defaultTime: this.defaultTime,
      parseDate: this.parseDate,
      mode: this.mode,
      onChange: (selectedDates) => {
        this.selectedDates = selectedDates;
      },
      /**
       * Workaround for flatpickr range modus losing
       * its value when a second date has not been chosen
       */
      onClose: (selectedDates, dateStr, instance) => {
        if (instance.config && instance.config.mode && instance.config.mode === 'range') {
          if (selectedDates.length === 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        }
      },
      plugins: [new LabelPlugin()],
    };

    // sanitize options if undefined or null (then defaults get used)
    settings = omitBy(settings, isNil);

    const flatpickrOptions = {
      flatpickr: null,
      selectedDate: this.defaultDate,
      selectedDates: [],
      text: this.addonText ? this.addonText : this.$i18n.t('addonText'),
      tooltip: this.addonTooltip ? this.addonTooltip : this.$i18n.t('addonTooltip'),
      settings,
    };

    return flatpickrOptions;
  },
  computed: {
    classes() {
      return [
        'vl-datepicker',
      ];
    },
    inputListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: () => {
            vm.$emit('input', this.selectedDates);
          },
        },
      );
    },
  },
  mounted() {
    const self = this;
    this.settings.positionElement = this.$refs.addon.$el;

    this.flatpickr = new Flatpickr(this.$el, this.settings);

    if (this.flatpickr) {
      if (this.modMarkToday && this.flatpickr.calendarContainer) {
        this.flatpickr.calendarContainer.classList.add('mark-today');
      }
      this.selectedDates = this.flatpickr.selectedDates;
      if (this.flatpickr.altInput) {
        this.flatpickr.altInput.classList.add('vl-datepicker__input-field');
        if (typeof this.flatpickr.altInput.addEventListener === 'function') {
          this.flatpickr.altInput.addEventListener('input', debounce((event) => {
            self.$emit('inputChanged', event);
          }, 200));
        }
      }
      // when default date is set through value instead of defaultDate
      if (this.value?.length) {
        this.selectedDates = this.value;
        this.flatpickr.setDate(this.value, false);
      }
    }
  },
  beforeDestroy() {
    const self = this;

    this.flatpickr.destroy();

    if (this.flatpickr && this.flatpickr.altInput) {
      if (typeof this.flatpickr.altInput.removeEventListener === 'function') {
        this.flatpickr.altInput.removeEventListener('input', debounce((event) => {
          self.$emit('inputChanged', event);
        }, 200));
      }
    }
  },
  watch: {
    value(newVal, oldValue) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldValue)) {
        this.flatpickr.setDate(newVal, false);
      }
    },
    modError() {
      // reflect 2-way binding state change to flatpicker input-field
      this.flatpickr.altInput.classList.toggle('vl-input-field--error');
    },
    modDisabled(newVal) {
      // reflect 2-way binding state change to flatpicker input-field
      if (newVal === true) {
        this.flatpickr.altInput.setAttribute('disabled', newVal);
      } else {
        this.flatpickr.altInput.removeAttribute('disabled');
      }
      this.flatpickr.altInput.classList.toggle('vl-input-field--disabled');
    },
    selectedDate(newVal, oldVal) {
      /**
       * Check if the date has actually changed before emitting
       * a change event. When no date has been chosen, pass
       * an empty string instead of an undefined var for the
       * date comparision to work as expected.
       */
      newVal = typeof newVal !== 'undefined' ? newVal : '';
      oldVal = typeof oldVal !== 'undefined' ? oldVal : '';

      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.$emit('input', this.selectedDates);
      } else if (newVal === '' && oldVal === '') {
        // Also emit when there are no values, otherwise the model will not update
        this.$emit('input', []);
      }
    },
    minDate(value) {
      this.flatpickr.set('minDate', value);
    },
    maxDate(value) {
      this.flatpickr.set('maxDate', value);
    },
  },
};
