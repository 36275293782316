import VlButton from '@govflanders/vl-ui-button/src/vue';
import VlInputField from '@govflanders/vl-ui-input-field/src/vue';
import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlButton,
    VlInputField,
    VlIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: String,
    /**
    * Display inline
    */
    modInline: Boolean,
    /**
    * Disables padded search
    */
    modAlt: Boolean,
    /**
    * Custom label text
    */
    labelText: String,
    /**
    * Custom submit label
    */
    submitText: String,
    /**
    * Custom reset text
    */
    resetText: String,
    /**
    * Hide submit button for live search
    */
    modLiveSearch: Boolean,
    /**
    * Disable reset button
    */
    modDisableFieldReset: Boolean,
    /**
    * Form action
    */
    action: String,
  },
  data() {
    return {
      searchValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.searchValue = newVal;
    },
  },
  computed: {
    classes() {
      return [
        'vl-search',
        { 'vl-search--inline': this.modInline },
        { 'vl-search--has-input-value': this.searchValue },
        { 'vl-search--alt': this.modAlt },
      ];
    },
    inputListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: (event) => {
            this.searchValue = event.target.value;
            vm.$emit('input', this.searchValue);
          },
        },
      );
    },
    showReset() {
      return !!this.searchValue;
    },
  },
  methods: {
    onReset() {
      this.searchValue = '';
      this.$emit('input', this.searchValue);
    },
  },
};
