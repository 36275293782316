import VlUTextfit from './vl-u-textfit/vl-u-textfit.vue';
import VlUSpacer from './vl-u-spacer/vl-u-spacer.vue';
import VlURuler from './vl-u-ruler/vl-u-ruler.vue';

import {
  VlUJsCheck,
  VlUTextClamp,
  VlUAlign,
  VlUFlex,
  VlUVisuallyHidden,
  VlUBackground,
  VlUPositioning,
  VlUPrint,
  VlUSticky,
  VlUText,
  VlUVisibility,
  VlUBreakpoint,
  VlUOffset,
  VlUBorder,
  VlUHighlightContent,
  VlUImageFallback,
  VlUMark,
  VlUWhitespace,
  VlUScrollOffset,
  VlUSpacer as VlUSpacerDirective,
} from './vl-directive';

export default {
  install(Vue) {
    // utility components (utils bounded to an element and with more logic)
    Vue.component('vl-u-textfit', VlUTextfit);
    Vue.component('vl-u-spacer', VlUSpacer);
    Vue.component('vl-u-ruler', VlURuler);

    // utility directives (helping utils with wrapping function and little logic)
    Vue.directive('vl-text-clamp', VlUTextClamp);
    Vue.directive('vl-js-check', VlUJsCheck);
    Vue.directive('vl-align', VlUAlign);
    Vue.directive('vl-flex', VlUFlex);
    Vue.directive('vl-visually-hidden', VlUVisuallyHidden);
    Vue.directive('vl-background', VlUBackground);
    Vue.directive('vl-positioning', VlUPositioning);
    Vue.directive('vl-print', VlUPrint);
    Vue.directive('vl-sticky', VlUSticky);
    Vue.directive('vl-text', VlUText);
    Vue.directive('vl-visibility', VlUVisibility);
    Vue.directive('vl-breakpoint', VlUBreakpoint);
    Vue.directive('vl-offset', VlUOffset);
    Vue.directive('vl-border', VlUBorder);
    Vue.directive('vl-highlight-content', VlUHighlightContent);
    Vue.directive('vl-image-fallback', VlUImageFallback);
    Vue.directive('vl-mark', VlUMark);
    Vue.directive('vl-whitespace', VlUWhitespace);
    Vue.directive('vl-scroll-offset', VlUScrollOffset);
    Vue.directive('vl-spacer', VlUSpacerDirective);
  },
};

export {
  VlUTextfit,
  VlUSpacer,
  VlUAlign,
  VlUFlex,
  VlUVisuallyHidden,
  VlUBackground,
  VlUPositioning,
  VlUPrint,
  VlURuler,
  VlUSticky,
  VlUText,
  VlUVisibility,
  VlUBreakpoint,
  VlUOffset,
  VlUBorder,
  VlUHighlightContent,
  VlUImageFallback,
  VlUMark,
  VlUWhitespace,
  VlUSpacerDirective,
};
