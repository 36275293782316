import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    showOnValue: [Number, String, Object, Array],
    selectedValue: [Number, String, Object, Array],
  },
  computed: {
    selectedValueChosen() {
      return this.showOnValue.toString() === this.selectedValue.toString();
    },
  },
};
