import VlTitle from '@govflanders/vl-ui-titles/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue/';
import VlSearch from '@govflanders/vl-ui-search/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlTitle,
    VlLink,
    VlSearch,
  },
  props: {
    tagName: {
      type: String,
      default: 'header',
    },
    title: {
      type: String,
      required: true,
    },
    titleLink: String,
    tabs: {
      type: Array,
    },
    modSearch: Boolean,
    searchPlaceholder: String,
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    classes() {
      return [
        'vl-functional-header',
        {
          'vl-functional-header--has-actions': this.$slots.actions,
        },
      ];
    },
    hasTabs() {
      return !!this.tabs;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchValue);
    },
  },
};
