import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    placeholderText: String,
    id: String,
    name: String,
    modBlock: Boolean,
    value: null,
    modNoPlaceholder: Boolean,
    modDisabled: Boolean,
    modError: Boolean,
    modSuccess: Boolean,
  },
  data() {
    return {
      selected: this.value || '',
    };
  },
  watch: {
    value() {
      this.selected = this.value || '';
    },
  },
  computed: {
    classes() {
      return [
        'vl-select', {
          'vl-select--block': this.modBlock,
          'vl-select--error': this.modError,
          'vl-select--success': this.modSuccess,
        },
      ];
    },
    changeListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: (event) => {
            vm.$emit('input', event.target.value);
          },
        },
      );
    },
    placeholderLabel() {
      return this.placeholderText ? this.placeholderText : this.$i18n.t('optionPlaceholder');
    },
  },
};
