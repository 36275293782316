import nlBE from './locale/nl-BE';

export default {
  i18n: {
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'a',
    },
    title: {
      type: String,
      required: true,
    },
    titleHref: String,
    text: String,
    textClampText: {
      type: Number,
      default: 2,
    },
    note: String,
    date: {
      type: Object,
      validator(date) {
        return date.datetime && date.day && date.month;
      },
      // required: datetime, day, month
      datetime: String,
      day: String,
      month: String,
    },
  },
  computed: {
    classes() {
      return [
        'vl-agenda-item',
        this.tagName !== 'a' ? 'vl-agenda-item--no-link' : false,
      ];
    },
  },
};
