import VlLink from '@govflanders/vl-ui-link/src/vue/';
import nlBE from '../../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlLink,
  },
  inheritAttrs: false,
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    number: {
      type: Number,
      required: true,
    },
    modActive: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-pager__element', {
          'vl-pager__element--active': this.modActive,
        },
      ];
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
