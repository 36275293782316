const VlUVisuallyHidden = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-visually-hidden';
    const modifiers = Object.keys(binding.modifiers);

    if (modifiers && modifiers.length) {
      modifiers.forEach(modifier => el.classList.add(`${baseClass}--${modifier}`));
    } else {
      el.classList.add(`${baseClass}`);
    }
  },
};

export default VlUVisuallyHidden;

export { VlUVisuallyHidden };
