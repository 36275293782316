import uniq from 'lodash/uniq';

export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    cols: {
      type: Array,
      default: null,
    },
    pushers: {
      type: Array,
      default: null,
    },
    width: [Number, String],
    widthL: [Number, String],
    widthM: [Number, String],
    widthS: [Number, String],
    widthXs: [Number, String],
    push: [Number, String],
    pushL: [Number, String],
    pushM: [Number, String],
    pushS: [Number, String],
    pushXs: [Number, String],
  },
  data() {
    return {
      formGridPrefix: '',
    };
  },
  computed: {
    classes() {
      const classArray = [];
      const colCount = 12;
      const sizes = ['', 'l', 'm', 's', 'xs'];
      let hasColumns = false;

      const isNumerical = number => !Number.isNaN(parseInt(number, 10));

      const getComputedWidth = (widthArray) => {
        let gridValue = colCount;
        let nom;
        let denom;

        if (isNumerical(widthArray) && isNumerical(widthArray[1])) {
          [nom, denom] = [...widthArray];
        }

        gridValue = (colCount / denom) * nom;

        return gridValue < colCount ? gridValue : colCount;
      };

      const formatClass = (width, modifier = null, isPush = false) => {
        let classWidth;
        let calculatedWidth;
        let widthOfColumn = colCount;

        const gridType = isPush ? 'push' : 'col';
        const formattedModifier = modifier ? `--${modifier}` : '';

        if (isNumerical(width)) {
          widthOfColumn = width.split(/\//); // Split on /

          if (widthOfColumn[1]) {
            calculatedWidth = getComputedWidth(widthOfColumn);
          } else {
            calculatedWidth = width;
          }

          classWidth = `vl-${this.formGridPrefix}${gridType}--${calculatedWidth}-${colCount}${formattedModifier}`;
        } else {
          widthOfColumn = width.split(/-/); // Split on -

          if (!widthOfColumn[1]) {
            switch (widthOfColumn[0]) {
              case 'full':
                calculatedWidth = colCount;
                break;
              case 'half':
                calculatedWidth = colCount / 2;
                break;
              case 'reset':
                calculatedWidth = 0;
                break;
              default:
                calculatedWidth = colCount;
                break;
            }
          } else {
            calculatedWidth = isNumerical(getComputedWidth(widthOfColumn)) ? getComputedWidth(widthOfColumn) : colCount;
          }

          classWidth = `vl-${this.formGridPrefix}${gridType}--${calculatedWidth}-${colCount}${formattedModifier}`;
        }

        if (gridType === 'push' && parseInt(calculatedWidth, 10) === 0) {
          classWidth = formattedModifier ? `vl-${this.formGridPrefix}push--reset${formattedModifier}` : `vl-${this.formGridPrefix}push--reset`;
        }

        if (classWidth) {
          classArray.push(classWidth);
        }
      };

      for (let i = 0; i < sizes.length; i++) {
        const size = sizes[i];
        const sizeCapitalized = size.replace(/^\w/, c => c.toUpperCase());

        if (this[`width${sizeCapitalized}`]) {
          formatClass(this[`width${sizeCapitalized}`], size);
          hasColumns = true;
        }

        if (this[`push${sizeCapitalized}`]) {
          formatClass(this[`push${sizeCapitalized}`], size, true);
          hasColumns = true;
        }

        if (!hasColumns) {
          classArray.push(`vl-${this.formGridPrefix}col--${colCount}-${colCount}`);
        }
      }

      return uniq(classArray);
    },
  },
};
