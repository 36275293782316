import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlInputField from '@govflanders/vl-ui-input-field/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlInputField,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    changeButtonText: String,
    value: [String, Number],
  },
  data() {
    return {
      inputValue: this.value,
      showInput: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-dynamic-label', {
          'vl-dynamic-label--active': this.showInput,
        },
      ];
    },
    changeButton() {
      return this.changeButtonText ? this.changeButtonText : this.$i18n.t('changeButton');
    },
    inputListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: (value) => {
            vm.$emit('input', value);
          },
        },
      );
    },
  },
  methods: {
    toggleInputField() {
      this.showInput = !this.showInput;
    },
  },
};
