import { render, staticRenderFns } from "./vl-tile-navigation-item.vue?vue&type=template&id=72eb3230&"
import script from "./vl-tile-navigation-item.js?vue&type=script&lang=js&"
export * from "./vl-tile-navigation-item.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports