import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from './locale/nl-BE';

export default {
  components: {
    VlIcon,
  },
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    modDisabled: Boolean,
    modError: Boolean,
    modSuccess: Boolean,
    title: String,
    icon: String,
    image: String,
    info: String,
    value: null,
    checked: null,
    id: String,
  },
  model: {
    prop: 'checked',
    event: 'input',
  },
  inheritAttrs: false,
  computed: {
    classes() {
      return [
        'vl-checkbox-tile',
        {
          'vl-checkbox-tile--disabled': this.modDisabled,
          'vl-checkbox-tile--error': this.modError,
          'vl-checkbox-tile--success': this.modSuccess,
        },
      ];
    },
    computedValue: {
      get() {
        return this.checked;
      },
      set(value) {
        if (Array.isArray(this.checked)) {
          if (this.checked.includes(this.value)) {
            this.checked.splice(this.checked.indexOf(this.value), 1);
          } else {
            this.checked.push(this.value);
          }
        }
        this.$emit('input', value);
      },
    },
  },
};
