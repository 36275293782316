import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modVertical: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-ol-map-tools-bar', {
          'vl-ol-map-tools-bar--vertical': this.modVertical,
        },
      ];
    },
  },
};
