import forEach from 'lodash/forEach';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      return [
        'vl-double-input__row',
      ];
    },
    getFor() {
      const firstField = this.$slots.default[0];

      return firstField.data.attrs.id;
    },
  },
  beforeUpdate() {
    const fields = this.$slots.default;

    forEach(fields, (field, index) => {
      field.componentOptions.listeners = field.componentOptions.listeners || {};

      field.componentOptions.listeners.focus = () => {
        this.$emit('focus', index);
      };

      field.componentOptions.listeners.blur = () => {
        this.$emit('blur', index);
      };
    });
  },
};
