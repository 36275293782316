import { VlColumn } from '@govflanders/vl-ui-core/src/vue';
import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  mixins: [VlColumn],
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    icon: String,
    label: String,
    subdata: String,
    modBordered: Boolean,
    modIconTop: Boolean,
  },
  computed: {
    labelClasses() {
      return [
        // Default
        'vl-description-data__label',
        {
          'vl-description-data__label--icon': this.icon,
          'vl-description-data__label--icon--top': this.modIconTop,
        },
      ];
    },
    hasLabelSlot() {
      return !!this.$slots.label;
    },
  },
};
