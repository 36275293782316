import { VlIcon } from '@govflanders/vl-ui-icon/src/vue';
import { VlTooltip } from '@govflanders/vl-ui-tooltip/src/vue';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  directives: {
    VlTooltip,
  },
  props: {
    tagName: {
      type: String,
      default: 'p',
    },
    tooltip: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: String,
    modDisabled: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-input-addon', {
          'vl-input-addon--disabled': this.modDisabled,
        },
      ];
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
  },
};
