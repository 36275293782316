import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modOffset: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-ol-map-search', {
          'vl-ol-map-search--offset': this.modOffset,
        },
      ];
    },
  },
};
