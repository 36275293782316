export default {
  data() {
    return {
      source: undefined,
    };
  },
  computed: {
    layer() {
      return this.$parent;
    },
  },
  watch: {
    source(source) {
      if (this.layer) {
        this.layer.source = source;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Please make sure the direct parent is a vl-ol-map-layer element');
      }
    },
  },
};
