import VlPopover from '@govflanders/vl-ui-popover/src/vue/';
import VlTitle from '@govflanders/vl-ui-titles/src/vue/';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlPopover,
    VlTitle,
  },
  props: {
    tagName: {
      type: String,
      default: 'nav',
    },
    label: {
      type: String,
      required: true,
    },
    titleSize: {
      type: String,
      default: 'h2',
    },
  },
  computed: {
    classes() {
      return [
        'vl-dropdown-navigation',
      ];
    },
  },
};
