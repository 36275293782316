import { VlSelect } from '@govflanders/vl-ui-select/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    selectedValue: [Number, String, Object, Array],
  },
  components: {
    VlSelect,
  },
};
