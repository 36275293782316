import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modToggle: Boolean,
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    /**
     * Watch for internal _change event triggered by vl-ol-map-base-layer-toggle child component
     */
    this.$on('_change', ($event, active) => {
      this.closeBaseLayer();
      this.$emit('change', { $event, active });
    });
  },
  computed: {
    classes() {
      return [
        'vl-ol-map-base-layer',
        { 'vl-ol-map-base-layer--open': this.open },
        { 'vl-ol-map-base-layer--toggle': this.modToggle },
      ];
    },
  },
  methods: {
    toggleBaseLayer() {
      this.open = !this.open;
    },
    closeBaseLayer() {
      this.open = false;
    },
  },
};
