import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'ul',
    },
    modBorder: Boolean,
    modBorderAlt: Boolean,
    modSmall: Boolean,
    modInline: Boolean,
    modDisplayInline: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-link-list', {
          'vl-link-list--bordered': this.modBorder,
          'vl-link-list--bordered-alt': this.modBorderAlt,
          'vl-link-list--small': this.modSmall,
          'vl-link-list--inline': this.modInline,
          'vl-link-list--display-inline': this.modDisplayInline,
        },
      ];
    },
  },
};
