import Popper from 'popper.js';
import { VlButton } from '@govflanders/vl-ui-button/src/vue';
import { VlIcon } from '@govflanders/vl-ui-icon/src/vue';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import Vue from 'vue';

import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlButton,
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    id: String,
    label: {
      text: String,
      tag: {
        type: String,
        default: 'button',
      },
      url: String,
      hidden: Boolean,
    },
    labelCloseAria: String,
    offsetX: {
      type: [Number, String],
      default: 0,
    },
    offsetY: {
      type: [Number, String],
      default: 0,
    },
    icon: String,
    /**
     * Align popover left from label
    */
    modLeft: Boolean,
    /**
     * Align popover right from label
    */
    modRight: Boolean,
    /**
     * Align popover center from label
    */
    modCenter: Boolean,
    /**
     * Add close button
    */
    modClosable: Boolean,
    /**
     * Rotate a custom-set icon with 90deg
    */
    modIconRotated: Boolean,
    /**
     * Larger default popover size (width: 45rem;)
    */
    modLarge: Boolean,
    /**
     * Makes the popover
    */
    modStandalone: Boolean,
    /**
     * Disables auto-focus on the popover content
    */
    modDisableFocus: Boolean,
    /**
     * The behavior used to change the popper's placement
    */
    flipBehavior: {
      type: Array,
      default: () => ['bottom', 'top'],
    },
  },
  data() {
    return {
      latestPopoverZIndex: 804,
      isOpen: false,
      isClosing: false,
      popper: null,
      breakpoint: null,
      popperReference: null,
      popoverContentReference: null,
      backdropClass: 'vl-backdrop',
      backdropActiveClass: 'vl-backdrop--active',
      arrowAlignTop: false,
      arrowAlignBottom: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-popover', {
          'vl-popover--align-left': this.modLeft,
          'vl-popover--align-right': this.modRight,
          'vl-popover--align-center': this.modCenter,
          'vl-popover--align-top': this.arrowAlignTop,
          'vl-popover--align-bottom': this.arrowAlignBottom,
          'vl-popover--closable': this.modClosable,
          'vl-popover--large': this.modLarge,
          'vl-popover--static': this.modStandalone,
          'js-vl-popover--closing': this.isClosing,
          'js-vl-popover--open': this.isOpen,
        },
      ];
    },
    toggleClasses() {
      return [
        'vl-popover__toggle',
      ];
    },
    popoverContentClasses() {
      return [
        'vl-popover__content', {
          'vl-popover__content--close': !this.isOpen,
        },
      ];
    },
    popoverToggleLabel() {
      return this.label;
    },
    popoverToggleLabelHidden() {
      if (this.label) {
        return this.label.hidden;
      }
      return false;
    },
    popoverAdvancedLabel() {
      return !!this.$slots.label;
    },
    popoverToggleIcon() {
      const icon = {};

      icon.name = this.icon ? this.icon : 'arrow';
      icon.default = !this.icon;
      icon.rotation = this.icon ? this.modIconRotated : true;
      icon.classes = ['vl-popover__toggle-icon'];

      if (!this.icon) {
        icon.classes = [
          'vl-vi-u-badge',
          'vl-vi-u-badge--small',
        ];

        icon.rotation = true;
      }

      return icon;
    },
  },
  methods: {
    createPopover() {
      this.createElementsForMobile();
      if (this.breakpoint !== 'xsmall' || this.breakpoint !== 'small') {
        this.popper = this.initiatePopper();
      }
    },
    resizePopover() {
      if (this.breakpoint === 'xsmall' || this.breakpoint === 'small') {
        this.createElementsForMobile();
        this.removeZIndex(this.popoverContentReference);

        if (this.popper) {
          this.popper.destroy();
          this.popper = null;
        }
      } else if (!this.popper) {
        this.popper = this.initiatePopper();
      }
    },
    initiatePopper() {
      const options = {
        placement: 'bottom-end',
        modifiers: {
          offset: {
            offset: '0,10',
          },
          flip: {
            behavior: this.flipBehavior,
          },
        },
      };

      if (this.modRight) {
        options.modifiers.offset.offset = '0, 10';
      }

      const { offsetX, offsetY } = this;

      if (offsetX && offsetY) {
        options.modifiers.offset.offset = `${offsetX}, ${offsetY}`;
      } else if (offsetX) {
        options.modifiers.offset.offset = `${offsetX}, 10`;
      } else if (offsetY) {
        options.modifiers.offset.offset = `0, ${offsetY}`;
      }

      if (this.modLeft) {
        options.placement = 'bottom-start';
      } else if (this.modCenter) {
        options.placement = 'bottom';
      }

      this.popper = new Popper(this.popoverReference, this.popoverContentReference, options);

      // Initial updateBound (can fix slight issues with positioning)
      this.popper.options.onCreate = () => {
        this.popper.state.updateBound();
      };

      this.popper.options.onUpdate = () => {
        this.updatePopoverAlignment();
      };

      return this.popper;
    },
    handleEscape() {
      if (this.isOpen) {
        this.closePopover();
      }
    },
    togglePopover() {
      if (this.isOpen) {
        this.closePopover();
      } else {
        this.openPopover();
      }
    },
    openPopover() {
      this.isOpen = true;

      if (this.backdrop) {
        this.backdrop.classList.add(this.backdropActiveClass);
      }

      if (this.popoverContentReference) {
        if (this.breakpoint !== 'small' && this.breakpoint !== 'xsmall' && !this.modDisableFocus) {
          Vue.nextTick(() => {
            this.popoverContentReference.focus();
          });
        }

        if (this.breakpoint !== 'small' && this.breakpoint !== 'xsmall') {
          this.updateZIndex(this.popoverContentReference);
        } else {
          this.removeZIndex(this.popoverContentReference);
        }
      }
    },
    closePopover() {
      if (this.isOpen) {
        this.isOpen = false;
        this.isClosing = true;

        if (this.backdrop) {
          this.backdrop.classList.remove(this.backdropActiveClass);
        }

        setTimeout(() => {
          this.isClosing = false;
        }, 500);
      }
    },
    updateZIndex(element) {
      this.latestPopoverZIndex = element.style.zIndex ? element.style.zIndex : this.latestPopoverZIndex;
      element.style.zIndex = ++this.latestPopoverZIndex;
      if (this.$refs.arrow) {
        this.$refs.arrow.style.zIndex = this.$refs.popoverContentReference.style.zIndex + 1;
      }
    },
    removeZIndex(element) {
      element.style.zIndex = null;
      if (this.$refs.arrow) {
        this.$refs.arrow.style.zIndex = null;
      }
    },
    breakpointChanged(breakpoint) {
      requestAnimationFrame(() => {
        this.breakpoint = breakpoint;
        this.resizePopover();
      });
    },
    isClickOutside(target, element) {
      if (element) {
        return target !== element && !element.contains(target);
      }
      return false;
    },
    createElementsForMobile() {
      this.backdrop = document.querySelector(`.${this.backdropClass}`);
      if (!this.backdrop) {
        const backdropElement = document.createElement('div');
        backdropElement.classList.add(this.backdropClass);
        document.body.appendChild(backdropElement);
        this.backdrop = backdropElement;
      }
      this.backdrop.addEventListener('click', this.closePopover);
    },
    contentClickListeners() {
      // If links inside the slot, close popover when click on link
      const defaultContent = get(this, '$slots.default', null);

      if (defaultContent) {
        forEach(defaultContent, (content) => {
          const element = content.elm;
          const links = element && typeof element.querySelectorAll !== 'undefined' ? element.querySelectorAll('a[href]') : null;

          if (links && links.length) {
            forEach(links, (link) => {
              link.addEventListener('click', () => {
                this.togglePopover();
              });
            });
          }
        });
      }
    },
    updatePopoverAlignment() {
      const alignment = this.popoverContentReference.getAttribute('x-placement');
      if (alignment) {
        this.arrowAlignBottom = alignment.indexOf('bottom') >= 0;
        this.arrowAlignTop = alignment.indexOf('top') >= 0;
        this.popoverContentReference.setAttribute('aria-expanded', this.isOpen.toString());
      }
    },
    getBreakpoint() {
      return window
        .getComputedStyle(document.body, ':before')
        .getPropertyValue('content')
        .replace(/"/g, '');
    },
    setReference(element) {
      if (element && this.popper) {
        this.popper.reference = element;
        this.popper.update();
      }
    },
  },
  mounted() {
    this.popoverReference = this.$refs.popoverReference;
    this.popoverContentReference = this.$refs.popoverContentReference;

    document.addEventListener('click', (event) => {
      if (
        this.isOpen && this.isClickOutside(event.target, this.popoverContentReference)
        && this.isClickOutside(event.target, this.popper ? this.popper.reference : null)
      ) {
        this.togglePopover();
      }
    });

    this.createPopover();
    this.contentClickListeners();

    const initialBreakpoint = this.getBreakpoint();
    this.breakpointChanged(initialBreakpoint);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopover);

    if (this.backdrop) {
      this.backdrop.removeEventListener('click', this.closePopover);
    }

    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  },
};
