import VlTitle from '@govflanders/vl-ui-titles/src/vue';

export default {
  components: {
    VlTitle,
  },
  props: {
    id: {
      default: null,
    },
    label: {
      required: true,
    },
    prefix: {
      default: '',
    },
    suffix: {
      default: '',
    },
    isDisabled: {
      default: false,
    },
    modShowTitle: Boolean,
  },
  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    header() {
      return `${this.prefix}${this.label}${this.suffix}`;
    },

    hash() {
      return this.isDisabled ? '#' : `#${this.uid}`;
    },

    uid() {
      let uid = null;

      if (this.label && !this.id) {
        uid = this.label.toLowerCase().replace(/ /g, '-');
      } else if (this.id) {
        uid = this.id;
      } else {
        uid = this._uid;
      }

      return uid;
    },
  },
};
