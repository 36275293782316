import debounce from 'lodash/debounce';
import { VlIcon } from '@govflanders/vl-ui-icon/src/vue';
import { VlGrid, VlColumn } from '@govflanders/vl-ui-core/src/vue';
import { VlLink } from '@govflanders/vl-ui-link/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlGrid,
    VlColumn,
    VlLink,
  },
  props: {
    modFullWidth: Boolean,
    modSmall: Boolean,
    tagName: {
      default: 'div',
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      drawers: [],
      currentDrawerIndex: -1,
      currentToggle: null,
      resizeListener: null,
    };
  },
  computed: {
    classes() {
      return [
        'vl-dynamic-drawers',
      ];
    },
  },
  mounted() {
    const slotChilds = this.$slots.default;

    for (let i = 0; i < slotChilds.length; i++) {
      const drawer = slotChilds[i].componentInstance;
      if (drawer) {
        drawer.$on('close', this.closeDrawer);
        drawer.modFullWidth = this.modFullWidth;
        drawer.id = `${this.id}-${i}`;
        this.drawers.push(drawer);
      }
    }

    this.resizeListener = debounce(() => {
      if (this.currentDrawerIndex > -1) {
        this.repositionDrawer();
      }
    }, 250);

    window.addEventListener(
      'resize',
      this.resizeListener,
    );
  },
  beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.resizeListener,
    );
  },
  methods: {
    openDrawer(index, e) {
      this.currentDrawerIndex = index;
      const currentDrawer = this.drawers[this.currentDrawerIndex];
      currentDrawer.isActive = !currentDrawer.isActive;

      this.drawers
        .filter((drawer) => drawer !== currentDrawer)
        .forEach((drawer) => {
          drawer.isActive = false;
        });

      this.currentToggle = e.target;
      this.repositionDrawer();
    },
    closeDrawer() {
      const { toggles } = this.$refs;
      toggles[this.currentDrawerIndex].$el.focus();
      this.currentDrawerIndex = -1;
    },
    repositionDrawer() {
      const currentDrawer = this.drawers[this.currentDrawerIndex];
      const toggleEl = this.currentToggle;
      const toggleElTopPos = toggleEl.getBoundingClientRect().top;
      const { toggles } = this.$refs;
      let row = -1;
      for (let i = 0; i < toggles.length; i++) {
        const toggleRef = toggles[i].$el;
        const topPos = toggleRef.getBoundingClientRect().top;
        if (topPos > toggleElTopPos) {
          row = i;
          break;
        }
      }

      const fullWidthLeftPos = toggleEl.getBoundingClientRect().left + toggleEl.offsetLeft;
      const smallLeftPos = toggleEl.parentElement.offsetLeft + toggleEl.offsetLeft;
      const leftPos = this.modFullWidth ? fullWidthLeftPos : smallLeftPos;
      currentDrawer.tipPosition = leftPos + (toggleEl.getBoundingClientRect().width / 2);

      const contentEl = currentDrawer.$el;
      if (row > -1) {
        toggles[row].$el.parentElement.parentElement.insertBefore(contentEl, toggles[row].$el.parentElement);
      } else {
        toggles[toggles.length - 1].$el.parentElement.parentElement.insertBefore(
          contentEl, toggles[toggles.length - 1].$el.parentElement.nextSibling,
        );
      }
    },
  },
};
