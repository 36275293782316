import debounce from 'lodash/debounce';

const VlEqualHeight = {
  bind: (el, binding) => {
    if (binding.arg === 'container') {
      VlEqualHeight.updateHeight(el);
      VlEqualHeight.init(el);
    } else {
      el.setAttribute('data-equal-height-element', true);
    }
  },
  inserted: (el, binding) => {
    if (binding.arg === 'container') {
      VlEqualHeight.updateHeight(el);
    }
  },
  update: (el, binding) => {
    if (binding.arg === 'container') {
      VlEqualHeight.updateHeight(el);
    }
  },
  componentUpdated: (el, binding) => {
    if (binding.arg === 'container') {
      VlEqualHeight.updateHeight(el);
    }
  },
  unbind: (el, binding) => {
    if (binding.arg === 'container') {
      window.removeEventListener(
        'resize',
        debounce(() => {
          VlEqualHeight.updateHeight(el);
        }, 250),
      );
    }
  },
  updateHeight: (el) => {
    const nodeList = el.querySelectorAll('[data-equal-height-element]');
    const elems = Array.from(nodeList).map((elem) => {
      elem.style.minHeight = ''; // clean first
      elem.style.height = ''; // clean first
      return elem.offsetHeight;
    });

    const tallest = Math.max(...elems);

    for (let i = 0; i < nodeList.length; i++) {
      const elem = nodeList[i];
      elem.style.minHeight = `${tallest}px`; // #sizedoesmatter
    }
  },
  init: (containerEl) => {
    window.addEventListener(
      'resize',
      debounce(() => {
        VlEqualHeight.updateHeight(containerEl);
      }, 250),
    );
  },
};

export default VlEqualHeight;

export { VlEqualHeight };
