import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'p',
    },
    modBold: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-introduction', {
          'vl-introduction--bold': this.modBold,
        },
      ];
    },
  },
};
