import MultiClamp from 'multi-clamp';

const VlUTextClamp = {
  bind: (el, binding) => {
    const clampSize = parseInt(binding.value, 10);
    const fontsize = Math.round(parseFloat(window.getComputedStyle(el, null).getPropertyValue('font-size')));

    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) { // apply only in IE
      el.setAttribute('style', `font-size:${fontsize}px;`);
    }

    if (clampSize <= 0) {
      return;
    }
    // eslint-disable-next-line no-new
    new MultiClamp(el, { clamp: clampSize });
  },
  update: (el, binding) => {
    let clampSize = parseInt(binding.value, 10);
    if (clampSize <= 0) {
      clampSize = 999999; // reset to no clamp
    }
    // eslint-disable-next-line no-new
    new MultiClamp(el, { clamp: clampSize });
  },
};

export default VlUTextClamp;

export { VlUTextClamp };
