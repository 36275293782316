import VlGrid from '../vl-grid/vl-grid.vue';

export default {
  extends: VlGrid,
  data() {
    return {
      prefix: 'vl-form-grid',
    };
  },
};
