const VlUBreakpoint = {
  bind: (el, binding) => {
    const points = Object.keys(binding.modifiers);

    window.addEventListener(
      'DOMContentLoaded',
      VlUBreakpoint.resizeHandler.bind(el, points, binding.value),
    );

    window.addEventListener(
      'resize',
      VlUBreakpoint.resizeHandler.bind(el, points, binding.value),
      50,
    );
  },
  unbind: () => {
    window.removeEventListener('resize', VlUBreakpoint.resizeHandler);
  },
  resizeHandler: (points, bpCallback = () => {}) => {
    const bp = VlUBreakpoint.getBreakpoint();

    if (points.includes(bp)) {
      bpCallback();
    } else if (points.length < 1) {
      bpCallback(bp);
    }
  },
  getBreakpoint: () => window
    .getComputedStyle(document.body, ':before')
    .getPropertyValue('content')
    .replace(/"/g, ''),
};

export default VlUBreakpoint;

export { VlUBreakpoint };
