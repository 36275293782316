import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import VlButton from '@govflanders/vl-ui-button/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlButton,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    title: {
      type: String,
      required: true,
    },
    /**
     * Disable the next button
     */
    modNextDisabled: Boolean,
    /**
     * Disable the previous button
     */
    modPreviousDisabled: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-arrow-navigation',
      ];
    },
    arrowNextClasses() {
      return [
        'vl-arrow-navigation__arrow',
        'vl-arrow-navigation__arrow--next', {
          'vl-arrow-navigation__arrow--disabled': this.modNextDisabled,
        },
      ];
    },
    arrowPreviousClasses() {
      return [
        'vl-arrow-navigation__arrow',
        'vl-arrow-navigation__arrow--previous', {
          'vl-arrow-navigation__arrow--disabled': this.modPreviousDisabled,
        },
      ];
    },
  },
  methods: {
    clickNext(e) {
      this.$emit('next', e);
    },
    clickPrevious(e) {
      this.$emit('previous', e);
    },
  },
};
