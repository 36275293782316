import isNil from 'lodash/isNil';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.be';

const patterns = {
  iban: {
    blocks: [4, 4, 4, 4],
    prefix: 'BE',
    numericOnly: true,
    stripLeadingZeroes: false,
    numeralPositiveOnly: true,
  },
  rrn: {
    blocks: [2, 2, 2, 3, 2],
    delimiters: ['.', '.', '-', '.'],
    numericOnly: true,
    stripLeadingZeroes: false,
    numeralPositiveOnly: true,
  },
  date: {
    date: true,
    datePattern: ['d', 'm', 'Y'],
    delimiter: '.',
    stripLeadingZeroes: false,
  },
  price: {
    numeral: true,
    prefix: '€',
    rawValueTrimPrefix: true,
    noImmediatePrefix: true,
    numeralDecimalMark: ',',
    delimiter: ' ',
  },
  phone: {
    prefix: '+32',
    phone: true,
    phoneRegionCode: 'BE',
  },
  phoneinternational: {
    numericOnly: true,
    numeralPositiveOnly: true,
    delimiter: ' ',
  },
  uuid: {
    blocks: [8, 4, 4, 4, 12],
    delimiter: '-',
    hexadecimalOnly: true,
    stripLeadingZeroes: false,
  },
};

const patternInstances = [];

const initComponent = (el, binding) => {
  let patternType = null;

  if (binding.value) {
    if (binding.value.type) {
      patternType = binding.value.type.toLowerCase();
    }
  }

  const patternInstance = {};
  let pattern = {};

  if (patternType in patterns) {
    pattern = patterns[patternType];
  }

  if (binding.value) {
    if (!isNil(binding.value.prefix)) {
      pattern.prefix = binding.value.prefix;
    }

    if (!isNil(binding.value.customRule)) {
      Object.assign(pattern, binding.value.customRule);
    }

    /**
    * store current field
    */
    patternInstance.element = el;

    /**
    * store input-field default type
    */
    patternInstance.defaultInputType = el.type;

    /**
    * Cleave.js requires the fields to be field-type text
    */
    el.type = 'text';

    patternInstance.instance = new Cleave(el, pattern);
    patternInstance.pattern = pattern;
  }
  return patternInstance;
};

const findComponent = (el) => patternInstances.find((instance) => instance.element === el);

const createComponent = (el, binding, vnode) => {
  if (binding && binding.value && binding.value.type) {
    const component = initComponent(el, binding);
    setTimeout(() => component.instance.setRawValue(vnode?.data?.model?.value));
    patternInstances.push(component);
  }
};

const destroyComponent = (el) => {
  const component = findComponent(el);
  if (component) {
    component.element.type = component.defaultInputType;
    component.instance.destroy();
    patternInstances.splice(patternInstances.indexOf(component), 1);
  }
};

const updateComponent = (el, binding) => {
  let component = findComponent(el);
  if (component) {
    component.instance.destroy();
    component = initComponent(el, binding);
  }
};

const VlPattern = {
  bind(el, binding, vnode) {
    createComponent(el, binding, vnode);
  },
  update(el, binding, vnode) {
    const component = findComponent(el);
    if (component) {
      if (binding.value) {
        if ((patterns[binding.value.type.toLowerCase()] !== component.pattern)) {
          updateComponent(el, binding);
        } else if (el.value !== component.instance.properties.result) {
          setTimeout(() => {
            el.value = component.instance.properties.result;
            el.dispatchEvent(new Event('input', { bubbles: true }));
          });
        }
      } else {
        destroyComponent(el);
      }
    } else {
      createComponent(el, binding, vnode);
    }
  },
  unbind(el) {
    destroyComponent(el);
  },
};

export default VlPattern;

export { VlPattern };
