import FocusLock from 'vue-focus-lock';
import { VlIcon } from '@govflanders/vl-ui-icon/src/vue';
import { VlTitle } from '@govflanders/vl-ui-titles/src/vue';
import { VlButton } from '@govflanders/vl-ui-button/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlTitle,
    FocusLock,
    VlButton,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Enables the close button
     */
    closable: Boolean,
    /**
     * Open the modal by binding a variable
     */
    open: Boolean,
    id: {
      type: String,
      required: true,
    },
    /**
     * Activates the focus-lock
     */
    modLocked: {
      type: Boolean,
      default: true,
    },
    /**
     * Large width variant
     */
    modLarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Medium width variant
     */
    modMedium: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables background scroll
     */
    modEnableScroll: {
      type: Boolean,
      default: false,
    },
    /**
     * Right alignment
     */
    modRight: {
      type: Boolean,
      default: false,
    },
    /**
     * Right alignment
     */
    modMobileRight: {
      type: Boolean,
      default: false,
    },
    /**
     * Left alignment
     */
    modLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Left alignment
     */
    modMobileLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Bottom alignment
     */
    modBottom: {
      type: Boolean,
      default: false,
    },
    /**
     * Top alignment
     */
    modTop: {
      type: Boolean,
      default: false,
    },
    /**
     * Top alignment
     */
    modMobileTop: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable modal backdrop
     */
    modDisableBackdrop: {
      type: Boolean,
      default: false,
    },
    /**
     * Focus on the close button after opening the modal.
     * This works together with the 'closable' prop.
     */
    modFocusOnClose: {
      type: Boolean,
      default: false,
    },
    /**
     * Id of the element that get focus after opening the modal
     */
    modFocusId: String,
    /**
     * Modal title
     */
    title: String,
    /**
     * Content role
     */
    contentRole: {
      type: String,
      value: '',
    },
    /**
     * Optionally override the default z-index for the backdrop. The modal z-index is automatically adjusted.
     */
    zIndex: {
      type: Number,
    },
    titleTagName: {
      type: String,
      default: 'h2',
    },
    /**
     * Add role="alert" for status messages that appear after page load and don't contain interactive content.
     * Add role="alertdialog" for status messages that appear after page load that contain interactive content and
     * implement a focus jump to the content of the dialog.
     */
    role: {
      type: String,
      default: null,
      validator: (value) => ['alert', 'alertdialog'].indexOf(value) !== -1,
    },
    closeText: {
      type: String,
    },
    onCloseHook: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      modalOpened: this.open,
      showBackdrop: this.modalOpened && !this.modDisableBackdrop,
      noOverflowClass: 'vl-u-no-overflow',
      dialogZIndex: 0,
    };
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-modal',
      ];
    },
    modalDialogClasses() {
      return [
        'vl-modal-dialog', {
          'vl-modal-dialog--medium': this.modMedium,
          'vl-modal-dialog--large': this.modLarge,
          'vl-modal-dialog--right': this.modRight,
          'vl-modal-dialog--mobile-right': this.modMobileRight,
          'vl-modal-dialog--left': this.modLeft,
          'vl-modal-dialog--mobile-left': this.modMobileLeft,
          'vl-modal-dialog--bottom': this.modBottom,
          'vl-modal-dialog--top': this.modTop,
          'vl-modal-dialog--mobile-top': this.modMobileTop,
          'vl-modal-dialog--disable-backdrop': this.modDisableBackdrop,
        },
      ];
    },
    modalDialogTransition() {
      let transitionName = 'modal-slide';
      if (this.modLeft) {
        transitionName += '-from-left';
        return transitionName;
      }
      if (this.modRight) {
        transitionName += '-from-right';
        return transitionName;
      }
      if (this.modBottom) {
        transitionName += '-from-bottom';
        return transitionName;
      }
      if (this.modTop) {
        transitionName += '-from-top';
        return transitionName;
      }
      return 'modal-fade';
    },
    labelId() {
      return this.title ? `${this.id}-label` : false;
    },
    describedId() {
      return `${this.id}-description`;
    },
    reference() {
      return `${this.id}`;
    },
    focusLockDisabled() {
      return !(this.modalOpened && this.modLocked);
    },
    closeButtonText() {
      return this.closeText || this.$t('closeModal');
    },
  },
  mounted() {
    this.$root.$on('modal-toggle', (ref) => {
      if (this.reference === ref) {
        this.changeModalState(true);
      }
    });
    this.setBackdrop();
  },
  beforeDestroy() {
    document.body.classList.remove(this.noOverflowClass);
  },
  watch: {
    modalOpened: {
      immediate: true,
      handler(isOpen, wasOpen) {
        if (!this.modEnableScroll) {
          if (isOpen && !wasOpen) {
            document.body.classList.add(this.noOverflowClass);
          }
          if (!isOpen && wasOpen) {
            document.body.classList.remove(this.noOverflowClass);
          }
        }
      },
    },
    open: {
      immediate: false,
      handler(openNew, openOld) {
        if (openNew !== openOld) {
          this.changeModalState(true);
        }
      },
    },
  },
  methods: {
    onBackdropClick() {
      this.changeModalState(false, true);
    },
    setBackdrop() {
      this.$nextTick(() => {
        if (this.modalOpened && !this.modDisableBackdrop) {
          let backdrop = document.querySelector('.vl-modal-backdrop');
          // Backdrop exists already from other opened modal
          if (backdrop) {
            // remove previously set eventlisteners
            backdrop.replaceWith(backdrop.cloneNode(true));
            backdrop = document.querySelector('.vl-modal-backdrop');
            const zBackdrop = this.zIndex || Number(document.defaultView.getComputedStyle(backdrop).getPropertyValue('z-index'));
            if (backdrop) {
              backdrop.style.zIndex = zBackdrop + 1;
              this.dialogZIndex = zBackdrop + 2;
            }
            // no backdrop exists yet, first modal opened
          } else {
            const backdropEl = document.createElement('div');
            backdropEl.className = 'vl-modal-backdrop';
            this.$root.$el.appendChild(backdropEl);
            const zBackdrop = this.zIndex || Number(document.defaultView.getComputedStyle(backdropEl).getPropertyValue('z-index'));
            backdropEl.style.zIndex = zBackdrop;
            this.dialogZIndex = zBackdrop + 1;
            backdrop = backdropEl;
          }
          if (backdrop) {
            backdrop.addEventListener('click', this.onBackdropClick);
          }
        } else if (document.querySelectorAll('.vl-modal-dialog:not(.vl-modal-dialog--disable-backdrop)').length <= 1) {
          const backdrop = document.querySelector('.vl-modal-backdrop');
          if (backdrop) {
            backdrop.removeEventListener('click', this.onBackdropClick);
            backdrop.remove();
          }
        } else {
          const backdrop = document.querySelector('.vl-modal-backdrop');
          if (backdrop) {
            const zBackdrop = Number(document.defaultView.getComputedStyle(backdrop).getPropertyValue('z-index'));
            backdrop.style.zIndex = zBackdrop - 1;
            backdrop.removeEventListener('click', this.onBackdropClick);
          }
        }
      });
    },
    changeModalState(visible, disallowClosing = false) {
      if ((disallowClosing && this.closable) || visible) {
        if (this.onCloseHook && this.modalOpened) {
          this.onCloseHook();
        } else {
          this.modalOpened = !this.modalOpened;
        }
      }

      if (this.modalOpened) {
        this.$root.$emit('opened', this.id);
        this.activeElement = document.activeElement;
        this.handleFocus();
      } else {
        this.$root.$emit('closed', this.id);
        if (this.activeElement) {
          this.$nextTick(() => this.activeElement.focus());
        }
      }
      this.setBackdrop();
    },
    close() {
      this.modalOpened = false;
      this.changeModalState(false);
    },
    handleFocus() {
      if (this.closable && this.modFocusOnClose) {
        this.$nextTick(() => {
          if (this.$refs.closeButtonMobile) {
            this.$refs.closeButtonMobile.$el.focus();
          } else if (this.$refs.closeButton) {
            this.$refs.closeButton.$el.focus();
          }
        });
      } else if (this.modFocusId) {
        this.$nextTick(() => {
          const element = this.$el.querySelector(`#${this.modFocusId}`);
          if (element) {
            element.focus();
          }
        });
      }
    },
  },
};
