import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {},
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modTitle: Boolean,
    modTitleLarge: Boolean,
    modTitleMedium: Boolean,
    modTitleSmall: Boolean,
    modMeta: Boolean,
    modLabel: Boolean,
    modBadge: Boolean,
    modBodyText: Boolean,
    modBox: Boolean,
    modAnimated: {
      type: Boolean,
      default: true,
    },
    lineCount: {
      type: Number,
      default: 1,
    },
    a11yLabel: {
      type: String,
      default: 'Inhoud is aan het laden',
    },
    titleLevel: {
      type: Number,
      default: 1,
    },
    badgeSize: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    classes() {
      return [
        'vl-skeleton', {
          'vl-skeleton--box': this.modBox,
        },
      ];
    },
    boneClasses() {
      return [
        'vl-skeleton-bone', {
          'vl-skeleton-bone--title-large': this.modTitleLarge,
          'vl-skeleton-bone--title-medium': this.modTitleMedium,
          'vl-skeleton-bone--title-small': this.modTitleSmall,
          [`vl-skeleton-bone--title-h${this.titleLevel}`]: this.modTitle && this.titleLevel,
          'vl-skeleton-bone--meta': this.modMeta,
          'vl-skeleton-bone--label': this.modLabel,
          'vl-skeleton-bone--badge': this.modBadge,
          [`vl-skeleton-bone--badge--${this.badgeSize}`]: this.modBadge && this.badgeSize,
          'vl-skeleton-bone--body-text': this.modBodyText,
          'vl-skeleton-bone--box': this.modBox,
          'vl-skeleton-bone--animated': this.modAnimated,
        },
      ];
    },
  },
  mounted() {},
};
