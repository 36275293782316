import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'span',
    },
    icon: {
      type: String,
    },
    modSmall: Boolean,
    modLarge: Boolean,
    modLight: Boolean,
    modBefore: Boolean,
    modAfter: Boolean,
    modRotatedHalf: Boolean,
    modRotatedFull: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-icon',
        'vl-vi',
        this.icon ? `vl-vi-${this.icon}` : false,
        {
          'vl-icon--small': this.modSmall,
          'vl-icon--large': this.modLarge,
          'vl-icon--light': this.modLight,
          'vl-icon--before': this.modBefore,
          'vl-icon--after': this.modAfter,
          'vl-icon--inline-svg': this.svg,
          'vl-vi-u-90deg': this.modRotatedHalf,
          'vl-vi-u-180deg': this.modRotatedFull,
        },
      ];
    },
    svg() {
      let iconName = camelCase(this.icon);
      iconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);

      if (Vue.prototype.$icons && typeof Vue.prototype.$icons[iconName] !== 'undefined') {
        return Vue.prototype.$icons[iconName].icon;
      }
      return false;
    },
  },
};
