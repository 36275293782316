const VlUScrollOffset = {
  bind(el, binding) {
    // bindings
    const { id } = binding.value;
    const { offset } = binding.value;

    const offsetParentEl = document.createElement('span');
    const offsetEl = document.createElement('span');

    offsetParentEl.classList.add('vl-u-scroll-offset__parent');

    offsetEl.setAttribute('id', id);
    offsetEl.classList.add('vl-u-scroll-offset');

    if (offset) {
      offsetEl.style.marginTop = `-${offset}px`;
    }

    offsetParentEl.appendChild(offsetEl);

    el.insertBefore(offsetParentEl, el.firstChild);
  },
  unbind(el) {
    if (el) {
      const offsetParentEl = el.querySelector('.vl-u-scroll-offset__parent');

      if (offsetParentEl) {
        offsetParentEl.parentNode.removeChild(offsetParentEl);
      }
    }
  },
};

export default VlUScrollOffset;

export { VlUScrollOffset };
