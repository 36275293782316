const VlUWhitespace = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-whitespace';

    const modifiers = Object.keys(binding.modifiers);
    if (modifiers.length) {
      for (let i = 0; i < modifiers.length; i++) {
        const modifier = modifiers[i];

        el.classList.add(`${baseClass}--${modifier}`);
      }
    } else {
      el.classList.add(`${baseClass}`);
    }
  },
};

export default VlUWhitespace;

export { VlUWhitespace };
