import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modBordered: Boolean,
    modAlignRight: Boolean,
    modAlignCenter: Boolean,
    modSpaceBetween: Boolean,
    modCollapseXs: Boolean,
    modCollapseS: Boolean,
    modCollapseM: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-action-group',
        {
          'vl-action-group--bordered': this.modBordered,
          'vl-action-group--align-right': this.modAlignRight,
          'vl-action-group--align-center': this.modAlignCenter,
          'vl-action-group--space-between': this.modSpaceBetween,
          'vl-action-group--collapse--xs': this.modCollapseXs,
          'vl-action-group--collapse--s': this.modCollapseS,
          'vl-action-group--collapse--m': this.modCollapseM,
        },
      ];
    },
  },
};
