import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'button',
    },
    icon: String,
    id: String,
    name: String,
    modDisabled: Boolean,
    modToggle: Boolean,
    modRadio: Boolean,
    modActive: Boolean,
    a11yLabel: String,
  },
  data() {
    return {
      isActive: this.modActive,
    };
  },
  computed: {
    classes() {
      return [
        'vl-ol-map-tools-action', {
          'vl-ol-map-tools-action--active': this.isActive,
          'vl-ol-map-tools-action--disabled': this.modDisabled,
        },
      ];
    },
    inputListeners() {
      return { ...this.$listeners };
    },
  },

  beforeCreate() { },
  created() { },
  beforeMount() { },
  mounted() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },

  watch: {},

  methods: {
    clicked(e) {
      if (e.target.tagName !== 'A') {
        this.$emit('change');
      }
    },
  },
};
