const VlUVisibility = {
  bind: (el, binding) => {
    const baseClass = `vl-u-${binding.arg === 'hide' ? 'hidden' : 'visible'}`;
    const modifiers = Object.keys(binding.modifiers);

    if (modifiers && modifiers.length) {
      modifiers.forEach(modifier => el.classList.add(`${baseClass}--${modifier}`));
    } else {
      el.classList.add(`${baseClass}`);
    }
  },
};

export default VlUVisibility;

export { VlUVisibility };
