import VlBadge from '@govflanders/vl-ui-badge/src/vue';
import VlTitle from '@govflanders/vl-ui-titles/src/vue';
import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue';
import VlAccordion from '@govflanders/vl-ui-accordion/src/vue';
import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlInfoblock from '@govflanders/vl-ui-infoblock/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlBadge,
    VlTitle,
    VlAnnotation,
    VlAccordion,
    VlInfoblock,
    VlIcon,
    VlLink,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    icon: {
      type: String,
      default: 'info',
    },
    title: String,
    subtitle: String,
    moreLink: String,
    moreLinkText: String,
    modCollapse: Boolean,
    modFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'vl-contact-data',
        { 'vl-contact-data--full-width': this.modFullWidth },
      ];
    },
    contentClasses() {
      return [
        'vl-contact-data__content',
        { 'vl-contact-data__content--full-width': this.modFullWidth },
      ];
    },
  },
};
