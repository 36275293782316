const VlUText = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-text';

    const modifiers = Object.keys(binding.modifiers);
    for (let i = 0; i < modifiers.length; i++) {
      el.classList.add(`${baseClass}--${modifiers[i]}`);
    }
  },
};

export default VlUText;

export { VlUText };
