import { VlLightboxItem } from '@govflanders/vl-ui-lightbox/src/vue/';
import VlGalleryItem from './vl-gallery-item.js';

import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  mixins: [
    VlGalleryItem,
  ],
  components: {
    VlLightboxItem,
  },
  props: {
    lightBoxSize: String,
  },
  computed: {
    classes() {
      return [
        'vl-gallery__item',
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit('click-gallery-item');
    },
  },
};
