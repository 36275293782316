import TooltipJS from 'tooltip.js';

const _getPlacement = (modifiers) => {
  const {
    right, bottom, left,
  } = modifiers;
  if (right) {
    return 'right';
  } if (bottom) {
    return 'bottom';
  } if (left) {
    return 'left';
  }
  return 'top';
};

const VlTooltip = {
  bind: (el, binding) => {
    const placement = _getPlacement(binding.modifiers);
    const title = binding.value;
    const offset = '0,10';
    const template = `
    <div class="vl-tooltip vl-typography ${title.length > 80 ? 'vl-tooltip--large' : ''}" role="tooltip" aria-hidden="true">
      <div class="vl-tooltip__arrow"></div>
      <div class="tooltip__inner"></div>
    </div>`;

    const options = {
      placement,
      template,
      offset,
      html: true,
      title,
    };

    el.tooltipInstance = new TooltipJS(el, options);
    el.dataset.vlTooltipTitle = title;
    document.addEventListener('keyup', VlTooltip.keyupHandler.bind(el.tooltipInstance));

    if (binding.modifiers['hide-immediately']) {
      el.addEventListener('mouseleave', el.tooltipInstance.hide);
    }
  },
  unbind: () => {
    document.removeEventListener('keyup', VlTooltip.keyupHandler);
  },
  update: (el, { value }) => {
    if (el.dataset.vlTooltipTitle !== value) {
      el.tooltipInstance.updateTitleContent(value);
      el.dataset.vlTooltipTitle = value;
    }
  },
  keyupHandler: (evt, tooltip) => {
    evt = evt || window.event;
    let isEscape = false;

    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }

    if (isEscape) {
      tooltip.hide();
    }
  },
};

export default VlTooltip;

export { VlTooltip };
