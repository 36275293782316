import { VlEqualHeight } from '@govflanders/vl-ui-equal-height/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  directives: {
    VlEqualHeight,
  },
  props: {
    activeIndex: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      classes: [
        'vl-hero-navigation',
      ],
      items: [],
    };
  },
  mounted() {
    this.items = this.$children;
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].$on('active', () => { this.setActiveItem(i); });
    }
    this.setActiveItem(this.activeIndex);
  },
  watch: {
    activeIndex(value) {
      const index = parseInt(value, 10);
      if (index > -1 && index < this.items.length - 1) {
        this.setActiveItem(value);
      }
    },
  },
  methods: {
    setActiveItem(index) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].active = false;
      }
      this.items[index].active = true;
    },
    backgroundClasses(item) {
      return [
        'vl-hero-navigation__background',
        {
          'vl-hero-navigation__background--active': item.active,
        },
      ];
    },
  },
};
