import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlImage from '@govflanders/vl-ui-image/src/vue';
import VlTitle from '@govflanders/vl-ui-titles/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue';
import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue';
import VlTypography from '@govflanders/vl-ui-typography/src/vue';
import nlBE from './locale/nl-BE';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlImage,
    VlTitle,
    VlLink,
    VlAnnotation,
    VlTypography,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    to: null,
    href: String,
    image: {
      src: String,
      srcset: String,
      sizes: String,
      alt: String,
    },
    title: {
      type: String,
      required: true,
    },
    titleTagName: {
      type: String,
      default: 'h4',
    },
    titleTagStyle: {
      type: String,
      default: 'h5',
    },
    titleAnnotation: [String, Number],
    titleIcon: String,
    subtitle: String,
  },
  computed: {
    classes() {
      return [
        'vl-publication',
      ];
    },
    hasAnnotationSlot() {
      return !!this.$slots.annotation;
    },
    hasIntroSlot() {
      return !!this.$slots.intro;
    },
    hasTitleSlot() {
      return !!this.$slots.title;
    },
  },
};
