import toPairs from 'lodash/toPairs';
import forEach from 'lodash/forEach';
import VueI18n from 'vue-i18n';

import VlCore, { i18n } from '../package/vl-ui-core/src/vue';
import VlUtil from '../package/vl-ui-util/src/vue';

import * as components from './lib.components';

// Install the components
// TODO: Validate JSON Schema of options arg
export function install(Vue, options = {}) {
  if (this.installed) return;
  this.installed = true;

  forEach(
    // If no components option is defined, fall back to bulk installation
    toPairs(options.components || components),
    (entry) => Vue.component(entry[0], entry[1]),
  );

  Vue.use(VlCore);
  Vue.use(VlUtil);
  Vue.use(VueI18n);
  const messages = i18n;
  const vlI18n = new VueI18n({
    locale: 'nl-BE',
    messages,
  });
  Vue.use(vlI18n);
}

// Expose the components
export * from './lib.components';

export {
  VlCore,
  VlUtil,
  i18n,
};

// Plugin
export default {
  install,
};
