import forEach from 'lodash/forEach';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    labels: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      focussedColumn: null,
    };
  },
  computed: {
    classes() {
      return [
        'vl-double-input',
      ];
    },
  },
  mounted() {
    const rows = this.$slots.default;

    forEach(rows, (row) => {
      row.componentInstance.$on('focus', (index) => {
        this.focussedColumn = index;
      });

      row.componentInstance.$on('blur', () => {
        this.focussedColumn = null;
      });
    });
  },
  methods: {
    labelActive(index) {
      return (this.focussedColumn === index) ? 'js-vl-double-input-top-label--active' : '';
    },
  },
};
