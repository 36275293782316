import VlLink from '@govflanders/vl-ui-link/src/vue';
import debounce from 'lodash/debounce';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modPrewrapped: Boolean,
    textClamp: Number,
    readMoreLabel: String,
    readLessLabel: String,
  },
  data() {
    return {
      isOpen: false,
      isClamped: false,
      resizeListener: null,
    };
  },
  watch: {
    textClamp: () => {
      if (this) {
        this.detectOverflow();
      }
    },
  },
  components: {
    VlLink,
  },
  computed: {
    classes() {
      return [
        'vl-typography', {
          'vl-typography--prewrapped': this.modPrewrapped,
        },
      ];
    },
    readMoreIcon() {
      return this.isOpen ? 'nav-up' : 'nav-down';
    },
    readMoreLinkLabel() {
      return this.isOpen ? this.readLessLabel || this.$t('read_less_label') : this.readMoreLabel || this.$t('read_more_label');
    },
  },
  mounted() {
    if (this.textClamp) {
      this.$nextTick(() => {
        this.detectOverflow();
      });
      this.resizeListener = debounce(() => { this.onWindowResize(); }, 250);
      window.addEventListener('resize', this.resizeListener);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    toggleReadMore() {
      this.isOpen = !this.isOpen;
    },
    detectOverflow() {
      const el = this.$refs.clampedEl;
      if (el) {
        this.isClamped = el.scrollHeight > el.clientHeight;
      } else {
        this.isClamped = false;
      }
    },
    onWindowResize() {
      this.detectOverflow();
    },
  },
};
