const VlUPositioning = {
  bind: (el, obj) => {
    const baseClass = 'vl-u';
    let modifierClass;

    if (obj.value) {
      const key = Object.keys(obj.value)[0];

      if (obj.value[key]) {
        modifierClass = `${baseClass}-${key}-${obj.value[key]}`;
      }
    } else if (obj.arg) {
      modifierClass = `${baseClass}-${obj.arg}`;
    }

    if (modifierClass) {
      el.classList.add(modifierClass);
    }
  },
};

export default VlUPositioning;

export { VlUPositioning };
