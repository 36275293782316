import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import vlButtonMixin from '@govflanders/vl-ui-button/src/vue/vl-button-mixin';
import vlLinkMixin from './vl-link-mixin';
import nlBE from './locale/nl-BE';

const defaultTagName = 'a';

export default {
  mixins: [vlButtonMixin, vlLinkMixin],
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: defaultTagName,
    },
    to: null,
    href: null,
    /**
     * Prop to determine the exact matching of an active route
    */
    exact: Boolean,
    /**
     * Name of icon
    */
    icon: String,
    /**
     * Accessibility text shown if the link only has an icon
    */
    hiddenText: String,
    /**
     * Show icon before link
    */
    modIconBefore: Boolean,
    /**
     * Show icon after link
    */
    modIconAfter: Boolean,
    /**
     * Show icon only
    */
    modIconOnly: Boolean,
    /**
     * Show light icon
    */
    modIconLight: Boolean,
    /**
     * Show icon rotated 90 degrees
    */
    modIconRotatedHalf: Boolean,
    /**
     * Show icon rotated 180 degrees
    */
    modIconRotatedFull: Boolean,
    /**
     * Show an accessibility text after the link
    */
    a11yLabel: {
      type: String,
      default: null,
    },
    /**
     * Give the link the looks of a button
     */
    modButton: Boolean,
  },
  computed: {
    urlHref() {
      if (this.isNormalLink) {
        return this.href || this.urlTo;
      }
      return this.href || undefined;
    },
    urlTo() {
      return this.to || undefined;
    },
    classes() {
      return this.modButton ? this.buttonClasses : this.linkClasses;
    },
    iconClasses() {
      return this.modButton ? this.buttonIconClasses : this.linkIconClasses;
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
    linkAttributeName() {
      if (this.tag === 'router-link') {
        return 'to';
      }
      return 'href';
    },
    linkAttributeValue() {
      if (this.tag === 'router-link') {
        return this.urlTo;
      }
      return this.urlHref;
    },
    tag() {
      // If custom tagName use this
      if (this.tagName !== defaultTagName) {
        return this.tagName;
      }

      // If has 'to' prop, but is an exception and should be normal link
      if (this.isNormalLink) {
        return 'a';
      }

      // If has 'to' prop and is not an exception
      if (this.urlTo) {
        return 'router-link';
      }

      // If has url
      if (this.urlHref) {
        return 'a';
      }

      return defaultTagName;
    },
    hiddenLabel() {
      if (this.modIconOnly) {
        return this.hiddenText;
      }
      if (this.a11yLabel) {
        return this.a11yLabel;
      }
      return this.modExternal ? this.$i18n.t('generic.a11y.new_window') : undefined;
    },
    isNormalLink() {
      return this.urlTo && typeof this.urlTo === 'string' && this.urlTo.match(/^(http(s)?):\/\/|ftp:|mailto:|tel:/) || this.modExternal;
    },
  },
};
