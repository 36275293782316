import { VlLink } from '@govflanders/vl-ui-link/src/vue';
import forEach from 'lodash/forEach';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  data() {
    return {
      collapsed: true,
    };
  },
  components: {
    VlLink,
  },
  props: {
    /**
     * Tagname for the wrapper of a section in the search filter
    */
    tagName: {
      type: String,
      default: 'fieldset',
    },
    /**
     * Modifier to hide border
    */
    modNoBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * If true the item is collapsible
    */
    modCollapsible: {
      type: Boolean,
      default: false,
    },
    /**
     * Amount of items should be collapsed when mod-collapsible is true
    */
    modAmountCollapseable: {
      type: [String, Number],
      default: 6,
    },
    /**
     * Show all label
    */
    showAllLabel: {
      type: String,
      default() {
        return this.$t('showAll');
      },
    },
    /**
     * Show less label
    */
    showLessLabel: {
      type: String,
      default() {
        return this.$t('showLess');
      },
    },
  },
  computed: {
    classes() {
      return [
        'vl-search-filter__section',
        {
          'vl-search-filter__section--no-border': this.modNoBorder,
          'vl-search-filter__section--collapsible': !!this.modCollapsible,
          'vl-search-filter__section--collapsed': this.collapsed,
        },
      ];
    },
    showMoreIcon() {
      return this.collapsed ? 'nav-down' : 'nav-up';
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;

      this.collapseItems();
    },
    collapseItems() {
      if (this.modCollapsible) {
        if (this.$el) {
          const items = this.$el.querySelectorAll('[data-search-filter-item]');
          const itemsCollapsed = parseInt(this.modAmountCollapseable, 10);

          if (this.collapsed) {
            forEach(items, (item, index) => {
              if (index >= itemsCollapsed) {
                item.style.display = 'none';
              } else {
                item.style.display = 'block';
              }
            });
          } else {
            forEach(items, (item) => {
              item.style.display = 'block';
            });
          }
        }
      }
    },
  },
  mounted() {
    this.collapseItems();
  },
};
