import { VlButton } from '@govflanders/vl-ui-button/src/vue';
import { VlLink } from '@govflanders/vl-ui-link/src/vue';

export default {
  props: {
    tagName: {
      type: String,
      default: 'h1',
    },
    tagStyle: String,
    sublink: String,
    sublinkText: String,
    sublinkIcon: {
      type: String,
      default: '',
    },
    cta: String,
    ctaText: String,
    modBorder: Boolean,
    modAlt: Boolean,
    modSans: Boolean,
    modNoSpaceBottom: Boolean,
  },
  components: {
    VlButton,
    VlLink,
  },
  computed: {
    semanticTagStyle() {
      return this.tagStyle ? this.tagStyle : this.tagName;
    },
    wrapperClasses() {
      return [
        'vl-title-wrapper',
        `vl-title-wrapper--${this.semanticTagStyle}`, {
          'vl-title-wrapper--sublink': this.sublink,
          'vl-title-wrapper--cta': this.cta,
        },
      ];
    },
    isWrapped() {
      return !!this.sublink || !!this.cta;
    },
    classes() {
      return [
        'vl-title',
        `vl-title--${this.semanticTagStyle}`, {
          'vl-title--has-border': this.modBorder,
          'vl-title--alt': this.modAlt,
          'vl-title--sans': this.modSans,
          'vl-title--no-space-bottom': this.modNoSpaceBottom,
        },
      ];
    },
    linkType() {
      return this.cta ? 'vl-button' : 'vl-link';
    },
    linkText() {
      return this.cta ? this.ctaText : this.sublinkText;
    },
    linkUrl() {
      return this.cta ? this.cta : this.sublink;
    },
  },
};
