import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import VlLink from '@govflanders/vl-ui-link/src/vue/';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlLink,
  },
  props: {
    author: String,
    image: {
      src: String,
      alt: String,
    },
    reference: {
      href: String,
      text: String,
    },
    modRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'vl-quote', {
          'vl-quote--image-right': this.modRight,
        },
      ];
    },
  },
};
