import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    title: String,
    tagLine: String,
    href: String,
  },
  computed: {
    classes() {
      return [
        'vl-content-header__logo',
      ];
    },
    tagName() {
      return this.href ? 'a' : 'div';
    },
  },
};
