export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modXsmall: Boolean,
    modSmall: Boolean,
    modMedium: Boolean,
    modLarge: Boolean,
    modNone: Boolean,
  },

  computed: {
    classes() {
      return [
        'vl-u-spacer',
        this.modXsmall ? 'vl-u-spacer--xsmall' : false,
        this.modSmall ? 'vl-u-spacer--small' : false,
        this.modMedium ? 'vl-u-spacer--medium' : false,
        this.modLarge ? 'vl-u-spacer--large' : false,
        this.modNone ? 'vl-u-spacer--none' : false,
      ];
    },
  },
};
