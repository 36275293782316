import { VlIcon } from '@govflanders/vl-ui-icon/src/vue/';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    inheritAttrs: false,
    tagName: {
      type: String,
      default: 'li',
    },
    anchor: String,
    text: String,
    href: String,
    ariaExpanded: Boolean,
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-side-navigation__item', {
          'vl-side-navigation__item--parent': this.hasChildren,
        },
      ];
    },
    toggleClasses() {
      return [
        {
          'vl-side-navigation__toggle': true,
        },
      ];
    },
  },
  data() {
    return {
      expanded: false,
      hasChildren: false,
    };
  },
  methods: {
    toggleNav(event) {
      this.$emit('toggle', event.target);

      this.expanded = !this.expanded;
    },
  },
  components: {
    VlIcon,
  },
  mounted() {
    this.hasChildren = !!this.$slots.default;
  },
};
