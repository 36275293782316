import VlLink from '@govflanders/vl-ui-link/src/vue';
import { VlEqualHeight } from '@govflanders/vl-ui-equal-height/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  inheritAttributes: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlLink,
  },
  directives: {
    VlEqualHeight,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: false,
    },
    background: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classes() {
      return [
        'vl-hero-navigation__list__listitem',
        {
          'vl-hero-navigation__list__listitem--active': this.active,
        },
      ];
    },
    linkCta() {
      return this.linkText ? this.linkText : this.$i18n.t('readMore');
    },
  },
  methods: {
    activeItem() {
      this.$emit('active');
    },
  },
};
