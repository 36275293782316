import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    /**
     * Display badges condensed
    */
    modCondensed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'vl-badge-container', {
          'vl-badge-container--condensed': this.modCondensed,
        },
      ];
    },
  },
};
