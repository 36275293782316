import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    /**
     * Desired tagname
    */
    tagName: {
      type: String,
      default: 'div',
    },
    /**
    * Display as an inline element
    */
    modInline: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-input-group', {
          'vl-input-group--inline': this.modInline,
        },
      ];
    },
  },
};
