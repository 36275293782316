import VlLink from '@govflanders/vl-ui-link/src/vue/';
import nlBE from '../../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlLink,
  },
  inheritAttrs: false,
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return ['first', 'previous', 'next', 'last', 'ellipsis'].includes(type);
      },
    },
    label: String,
  },
  computed: {
    classes() {
      return 'vl-pager__element';
    },
    config() {
      const config = {
        first: {
          type: 'vl-link',
          icon: 'nav-left-double',
          iconBefore: true,
          text: this.label || this.$t('first'),
        },
        previous: {
          type: 'vl-link',
          icon: 'nav-left',
          iconBefore: true,
          text: this.label || this.$t('previous'),
        },
        next: {
          type: 'vl-link',
          icon: 'nav-right',
          iconAfter: true,
          text: this.label || this.$t('next'),
        },
        last: {
          type: 'vl-link',
          icon: 'nav-right-double',
          iconAfter: true,
          text: this.label || this.$t('last'),
        },
        ellipsis: {
          type: 'div',
          text: this.label || this.$t('ellipsis'),
          link: false,
        },
      };

      return config[this.type];
    },
    attributes() {
      return Object.assign(
        {},
        this.$attrs,
        {
          modIconBefore: this.config.iconBefore === true,
          modIconAfter: this.config.iconAfter === true,
        },
      );
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
