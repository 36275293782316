<script>
import { VlAccordionList } from '@govflanders/vl-ui-accordion/src/vue';

export default {
  extends: VlAccordionList,
  props: {
    modBordered: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$el.classList.add('vl-sidebar-accordion-list');
  },
};
</script>
