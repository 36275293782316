export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Adds alternative background color.
    */
    modAlt: Boolean,
    /**
     * Adds minimum padding.
    */
    modSmall: Boolean,
    /**
     * Adds medium padding.
    */
    modMedium: Boolean,
    /**
     * Removes the padding.
    */
    modNoSpace: Boolean,
    /**
     * Removes the padding at the top.
    */
    modNoSpaceTop: Boolean,
    /**
     * Removes the padding at the bottom.
    */
    modNoSpaceBottom: Boolean,
    /**
     * Ads a border on the top when 2 regions follow up on each other.
    */
    modNoSpaceBordered: Boolean,
    /**
     * Overlap the regions.
    */
    modOverlap: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-region',
        {
          'vl-region--overlap': this.modOverlap,
          'vl-region--alt': this.modAlt,
          'vl-region--small': this.modSmall,
          'vl-region--medium': this.modMedium,
          'vl-region--no-space': this.modNoSpace,
          'vl-region--no-space-top': this.modNoSpaceTop,
          'vl-region--no-space-bottom': this.modNoSpaceBottom,
          'vl-region--bordered': this.modNoSpaceBordered,
        },
      ];
    },
  },
};
