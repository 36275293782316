import VlRegion from './vl-region/vl-region.vue';
import VlLayout from './vl-layout/vl-layout.vue';
import VlGrid from './vl-grid/vl-grid.vue';
import VlFormGrid from './vl-form-grid/vl-form-grid.vue';
import VlColumn from './vl-column/vl-column.vue';
import VlFormColumn from './vl-form-column/vl-form-column.vue';
import VlPage from './vl-page/vl-page.vue';
import VlMain from './vl-main/vl-main.vue';
import i18n from './vl-i18n';

if (typeof window !== 'undefined') {
  require('../js/core.js'); /* eslint-disable-line */
}

// install core components by default.
export default {
  install(Vue) {
    Vue.component('vl-region', VlRegion);
    Vue.component('vl-layout', VlLayout);
    Vue.component('vl-grid', VlGrid);
    Vue.component('vl-form-grid', VlFormGrid);
    Vue.component('vl-column', VlColumn);
    Vue.component('vl-form-column', VlFormColumn);
    Vue.component('vl-page', VlPage);
    Vue.component('vl-main', VlMain);
  },
};

export {
  VlRegion,
  VlLayout,
  VlGrid,
  VlFormGrid,
  VlColumn,
  VlFormColumn,
  VlPage,
  VlMain,
  i18n,
};
