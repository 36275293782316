import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'a',
    },
    title: String,
    image: {
      src: String,
      alt: String,
      isInline: Boolean,
    },
    modAlt: Boolean,
  },

  // Computed properties
  computed: {
    classes() {
      return [
        'vl-doormat', {
          'vl-doormat--alt': this.modAlt,
          'vl-doormat--graphic': this.hasGraphic && !this.isInlineGraphic,
        },
      ];
    },
    hasGraphic() {
      return !!this.image;
    },
    isInlineGraphic() {
      if (this.hasGraphic) {
        if (this.image.isInline) {
          return true;
        }
      }
      return false;
    },
  },
};
