import nlBE from './locale/nl-BE';

export default {
  locale: 'nl-BE',
  i18n: {
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'ul',
    },
    modSmall: Boolean,
    modXsmall: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-icon-list',
        {
          'vl-icon-list--small': this.modSmall,
          'vl-icon-list--xsmall': this.modXsmall,
        },
      ];
    },
  },
};
