const VlUPrint = {
  bind: (el, binding) => {
    const baseClass = 'vl-u';
    el.classList.add(`${baseClass}-${binding.arg === 'no' ? 'hide-on-print' : 'show-on-print'}`);
  },
};

export default VlUPrint;

export { VlUPrint };
