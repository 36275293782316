import VlOlMapSource from '../vl-ol-map-source/vl-ol-map-source.vue';

let OSM;

if (typeof window !== 'undefined') {
  OSM = require('ol/source/OSM').default; /* eslint-disable-line */
}

export default {
  mixins: [VlOlMapSource],
  mounted() {
    this.source = new OSM();
  },
};
