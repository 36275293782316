import debounce from 'lodash/debounce';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    pushOn: {
      type: String,
      required: true,
    },
    pushMargin: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      ticking: false,
      resizeListener: null,
    };
  },
  methods: {
    requestTick() {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.update(this);
        });
      }

      this.ticking = true;
    },
    update(self) {
      self.ticking = false;

      self.dress(this.$el);
    },
    onScroll() {
      this.requestTick();
    },
    dress(element) {
      let elementDimensions;
      let pusherDimensions;
      let elementMarginBottom;
      const pusher = document.querySelector(`.${this.pushOn}`);
      const spacer = parseInt(this.pushMargin, 10);

      if (pusher) {
        elementDimensions = element.getBoundingClientRect();
        pusherDimensions = pusher.getBoundingClientRect();
        elementMarginBottom = Number(
          element.style.marginBottom.replace(/[^\d.-]/g, ''),
        );

        const space = Math.min(
          0,
          pusherDimensions.top - elementDimensions.bottom - spacer - elementMarginBottom,
        ) * -1;

        element.style.marginBottom = `${space}px`;
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `No pusher found with class .${this.pushOn}`,
        );
      }
    },
  },
  mounted() {
    this.requestTick();

    this.resizeListener = debounce(() => {
      this.onScroll();
    }, 100);

    if (this.$el) {
      window.addEventListener('scroll', this.resizeListener);
    }
  },
  beforeDestroy() {
    if (this.$el) {
      window.removeEventListener('scroll', this.resizeListener);
    }
  },
};
