import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    label: String,
    itemtype: String,
  },
  // Computed properties
  computed: {
    classes() {
      return [
        'vl-contact-card__data',
      ];
    },
    itemType() {
      const url = this.type ? `http://schema.org/${this.type}` : null;

      return url;
    },
  },
};
