import { VlColumn } from '@govflanders/vl-ui-core/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      default: 'div',
      type: String,
    },
    width: [Number, String],
    widthL: [Number, String],
    widthM: [Number, String],
    widthS: [Number, String],
    widthXs: [Number, String],
  },
  components: {
    VlColumn,
  },
  computed: {
    classes() {
      return [
        'vl-slide',
      ];
    },
  },
};
