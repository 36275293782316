const VlUMark = {
  bind: (el, obj) => {
    const baseClass = 'vl-u-mark';
    el.classList.add(baseClass);

    const modifiers = Object.keys(obj.modifiers);

    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      el.classList.add(`${baseClass}--${modifier}`);
    }
  },
};

export default VlUMark;

export { VlUMark };
