const errorClass = 'vl-image--error';

const VlUImageFallback = {
  bind: (el) => {
    el.addEventListener('error', () => {
      el.classList.add(errorClass);
    });

    el.addEventListener('load', () => {
      el.classList.remove(errorClass);
    });
  },
  unbind: (el) => {
    el.removeEventListener('error', () => {
      el.classList.add(errorClass);
    });

    el.removeEventListener('load', () => {
      el.classList.remove(errorClass);
    });
  },
};

export default VlUImageFallback;

export { VlUImageFallback };
