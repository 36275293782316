import {
  withValidation,
  validate,
  setInteractionMode,
  extend,
  localize,
  configure,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import IBAN from 'iban';
import nl from './locale/nl-BE';
import VlFormValidation from './vl-form-validation.vue';
import VlFormValidationObserver from './vl-form-validation-observer.vue';

extend('iban', value => {
  const pattern = /\s/g;
  let ibanValue = value.toString();

  ibanValue = ibanValue.replace(pattern, '');
  return IBAN.isValid(ibanValue);
});

extend('rrn', value => {
  const pattern = /^([0-9]{2})\.([0-9]{2})\.([0-9]{2})[-]([0-9]{3})\.([0-9]{2})$/gi;
  const rrnString = value.toString();

  const match = pattern.exec(rrnString);

  if (!match) {
    return false;
  }

  const year = match[1];
  const month = match[2];
  const day = match[3];
  const sequence = match[4];
  const verify = match[5];
  const validRRN = (97 - (year + month + day + sequence) % 97) === parseInt(verify, 10);
  const validRRN2000 = (97 - ('2' + year + month + day + sequence) % 97) === parseInt(verify, 10);

  if (!validRRN && !validRRN2000) {
    return false;
  }

  return true;
});

extend('phone', value => {
  const pattern = /^((\+|00)\s{0,1}32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$|^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/gi;
  const phoneString = value.toString();

  return pattern.test(phoneString);
});

extend('url', value => {
  // eslint-disable-next-line
  const pattern = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  const url = value.toString();

  return pattern.test(url);
});

extend('uuid', value => {
  let uuid,
    pattern;

  if (value) {
    uuid = value.toString();
    pattern = /^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/gi;

    const match = pattern.exec(uuid);

    return !!match;
  }

  return false;
});

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('nl', nl.validation);

export {
  VlFormValidation,
  VlFormValidationObserver,
  withValidation,
  setInteractionMode,
  validate,
  extend,
  localize,
  rules,
  configure,
  nl,
};
