import VlImage from '@govflanders/vl-ui-image/src/vue';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      locale: 'nl-BE',
      'nl-BE': nlBE,
    },
  },
  components: {
    VlImage,
  },
  props: {
    tagName: {
      type: String,
      default: 'a',
    },
    image: String,
    imageLink: String,
    videoPosterImage: String,
    publicationImage: String,
    dateTime: String,
    label: String,
    labelLink: String,
    title: String,
    text: String,
  },
  computed: {
    classes() {
      return [
        'vl-news-teaser',
        this.modPublication ? 'vl-news-teaser--is-publication' : false,
      ];
    },
    teaserImage() {
      if (this.videoPosterImage) {
        return this.videoPosterImage;
      }
      if (this.publicationImage) {
        return this.publicationImage;
      }
      return this.image;
    },
    modPublication() {
      return this.publicationImage;
    },
  },
};
