const toggleClass = (el, object) => {
  let clazz = 'vl-u-bg';
  if (object.arg) {
    clazz += `-${object.arg}`;
  }
  if (object.value === undefined || object.value) {
    el.classList.add(clazz);
  } else {
    el.classList.remove(clazz);
  }
};

const VlUBackground = {
  bind: (el, object) => {
    toggleClass(el, object);
  },
  update: (el, object) => {
    toggleClass(el, object);
  },
};

export default VlUBackground;

export { VlUBackground };
