const VlUSpacer = {
  bind: (el, binding) => {
    const baseClass = 'vl-u-spacer';

    const modifiers = Object.keys(binding.modifiers);
    const argClass = `${baseClass}${binding.arg ? `-${binding.arg}` : ''}`;
    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      el.classList.add(`${argClass}--${modifier}`);
    }
    if (!modifiers.length) {
      el.classList.add(argClass);
    }
  },
};

export default VlUSpacer;

export { VlUSpacer };
