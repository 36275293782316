import { VlButton } from '@govflanders/vl-ui-button/src/vue';
import { VlBadge } from '@govflanders/vl-ui-badge/src/vue';
import { VlLink } from '@govflanders/vl-ui-link/src/vue';
import nlBE from '../locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlButton,
    VlBadge,
    VlLink,
  },
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    title: {
      required: true,
      type: String,
    },
    cta: {
      title: String,
      href: String,
    },
    icon: String,
    iconSub: String,
    badgeIcon: String,
    titleAnnotation: String,
    subtitle: String,
    href: String,
    modLink: Boolean,
    modHighlighted: Boolean,
    modDisabled: Boolean,
    modAccordion: [Boolean, String],
    modSuccess: Boolean,
    modWarning: Boolean,
    modError: Boolean,
  },
  data() {
    return {
      accordionOpened: this.modAccordion === 'open',
    };
  },
  computed: {
    classes() {
      return [
        'vl-step', {
          'vl-step--has-link': !!this.href,
          'vl-step--highlighted': this.modHighlighted,
          'vl-step--disabled': this.modDisabled,
          'vl-step--accordion': this.modAccordion,
          'vl-step--success': this.modSuccess,
          'vl-step--warning': this.modWarning,
          'vl-step--error': this.modError,
          'vl-step--sub-no-icon': this.$parent.modSub && !this.icon && !this.badgeIcon,
          'js-vl-accordion--open': this.accordionOpened,
        },
      ];
    },
    anchorTagName() {
      return this.href ? 'a' : 'div';
    },
    anchorClasses() {
      return ['vl-step__container',
        {
          'vl-step__link': !!this.href,
        },
      ];
    },
    headerTagName() {
      return this.modAccordion ? 'button' : 'div';
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasAlertSlot() {
      return !!this.$slots.alert;
    },
    ariaExpanded() {
      if (!this.modAccordion) {
        return false;
      }
      return this.accordionOpened.toString();
    },
  },
  methods: {
    toggleAccordion() {
      this.accordionOpened = !this.accordionOpened;
    },
  },
  watch: {
    modAccordion(value) {
      this.accordionOpened = value === 'open';
    },
  },
};
