import VlCheckboxTile from '@govflanders/vl-ui-checkbox-tile/src/vue';

export default {
  mixins: [VlCheckboxTile],
  computed: {
    classes() {
      return [
        'vl-radio-tile',
        {
          'vl-radio-tile--disabled': this.modDisabled,
          'vl-radio-tile--error': this.modError,
          'vl-radio-tile--success': this.modSuccess,
        },
      ];
    },
  },
};
