export default {
  props: {
    /**
     * Use block variant of link
     */
    modBlock: Boolean,
    /**
     * Use bold variant of link
     */
    modBold: Boolean,
    /**
     * Use small variant of link
     */
    modSmall: Boolean,
    /**
     * Use large variant of link
     */
    modLarge: Boolean,
    /**
     * Error variant of link
     */
    modError: Boolean,
    /**
     * Use inline variant of link
     */
    modInline: Boolean,
    /**
     * Show label with external icon
    */
    modExternal: Boolean,
    /**
     * Remove all default link classes
     */
    modNoClass: Boolean,
    /**
     * Link line clamp size
     */
    textClamp: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    linkClasses() {
      const clazz = 'vl-link';
      return {
        [`${clazz}`]: !this.modNoClass,
        [`${clazz}--block`]: this.modBlock,
        [`${clazz}--bold`]: this.modBold,
        [`${clazz}--small`]: this.modSmall,
        [`${clazz}--large`]: this.modLarge,
        [`${clazz}--inline`]: this.modInline,
        [`${clazz}--flex`]: this.textClamp > 0,
        [`${clazz}--error`]: this.modError,
      };
    },
    linkIconClasses() {
      const clazz = 'vl-link__icon';
      return [
        `${clazz}`, {
          [`${clazz}--after`]: this.modIconAfter || this.modExternal,
          [`${clazz}--before`]: this.modIconBefore,
          [`${clazz}--standalone`]: this.modIconOnly,
          [`${clazz}--light`]: this.modIconLight,
        },
      ];
    },
  },
};
