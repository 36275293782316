import VlIcon from '@govflanders/vl-ui-icon/src/vue/';
import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue/';
import VlBadge from '@govflanders/vl-ui-badge/src/vue/';
import nlBE from './locale/nl-BE';

const knownModifiers = ['contact', 'publications', 'faq', 'news', 'timeline', 'question'];

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  components: {
    VlIcon,
    VlAnnotation,
    VlBadge,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    modType: {
      type: String,
      validator: value => !value || knownModifiers.includes(value),
    },
    title: {
      type: String,
      required: true,
    },
    titleTagName: {
      type: String,
      default: 'h2',
    },
    icon: {
      type: String,
      default: '',
    },
    annotation: [String, Number],
    modWithNotification: Boolean,
    modAlt: Boolean,
    modBorder: Boolean,
  },
  computed: {
    classes() {
      return [
        'vl-infoblock',
        this.modType ? `vl-infoblock--${this.modType}` : false,
        this.modBorder ? 'vl-infoblock--border' : false,
        this.modAlt ? 'vl-infoblock--alt' : false,
      ];
    },
  },
};
